import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, Input, Form, Switch, message } from 'antd';
import axios from 'axios';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';

function UsersScreen() {
    const [users, setUsers] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [currentUser, setCurrentUser] = useState(null);
    const [showPassword, setShowPassword] = useState(false);
    const [form] = Form.useForm();

    useEffect(() => {
        fetchUsers();
    }, []);

    const fetchUsers = async () => {
        try {
            const response = await axios.get('/api/users/getallusers');
            setUsers(response.data);
        } catch (error) {
            console.log(error);
        }
    };

    const handleCreate = () => {
        setIsEditing(false);
        setIsModalOpen(true);
        form.resetFields();
    };

    const handleEdit = (record) => {
        setCurrentUser(record);
        setIsEditing(true);
        setIsModalOpen(true);
        form.setFieldsValue(record);
    };

    const handleDelete = async (id) => {
        try {
            await axios.delete(`/api/users/deleteuser/${id}`);
            message.success('User deleted successfully');
            fetchUsers();
        } catch (error) {
            message.error('Failed to delete user');
            console.log(error);
        }
    };

    const handleToggleAdmin = async (id) => {
        try {
            await axios.put(`/api/users/assignadmin/${id}`);
            message.success('Admin status updated');
            fetchUsers();
        } catch (error) {
            message.error('Failed to update admin status');
            console.log(error);
        }
    };

    const handleModalOk = async () => {
        try {
            const values = await form.validateFields();
            if (isEditing) {
                // Update existing user
                await axios.put(`/api/users/edituser/${currentUser._id}`, values);
                message.success('User updated successfully');
            } else {
                // Create new user
                await axios.post('/api/users/register', values);
                message.success('User created successfully');
            }
            setIsModalOpen(false);
            fetchUsers();
        } catch (error) {
            message.error('Failed to save user');
            console.log(error);
        }
    };

    const handleModalCancel = () => {
        setIsModalOpen(false);
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const columns = [
        { title: 'User Name', dataIndex: 'name', key: 'name' },
        { title: 'User Email', dataIndex: 'email', key: 'email' },
        {
            title: 'Role',
            dataIndex: 'isAdmin',
            key: 'isAdmin',
            render: (text, record) => (
                <Switch checked={record.isAdmin} onChange={() => handleToggleAdmin(record._id)} />
            ),
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (text, record) => (
                <>
                    <Button type="link" onClick={() => handleEdit(record)}>Edit</Button>
                    <Button type="link" danger onClick={() => handleDelete(record._id)}>Delete</Button>
                </>
            ),
        },
    ];

    return (
        <div className='row ms-5 mb-3'>
            <div className='col-md-10'>
                <h1>Users Management</h1>
                <Button type="primary" onClick={handleCreate} style={{ marginBottom: '16px' }}>Add User</Button>
                <Table dataSource={users} columns={columns} rowKey="_id" />

                {/* Modal for Add/Edit User */}
                <Modal
                    title={isEditing ? "Edit User" : "Add User"}
                    visible={isModalOpen}
                    onOk={handleModalOk}
                    onCancel={handleModalCancel}
                >
                    <Form form={form} layout="vertical">
                        <Form.Item
                            name="name"
                            label="Name"
                            rules={[{ required: true, message: 'Please enter the name' }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="email"
                            label="Email"
                            rules={[{ required: true, message: 'Please enter the email' }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="password"
                            label="Password"
                            rules={[{ required: !isEditing, message: 'Please enter the password' }]}
                        >
                            <Input.Password
                                type={showPassword ? 'text' : 'password'}
                                iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                addonAfter={
                                    <Button type="text" icon={showPassword ? <EyeInvisibleOutlined /> : <EyeTwoTone />} onClick={togglePasswordVisibility} />
                                }
                            />
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
        </div>
    );
}

export default UsersScreen;
