import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';

const BookingResult = () => {
    const location = useLocation();
    const [statusMessage, setStatusMessage] = useState('Processing...');

    const params = new URLSearchParams(location.search);
    const txnResponseCode = params.get('vpc_TxnResponseCode');
    const bookingId = params.get('vpc_OrderInfo');
    const transactionNo = params.get('vpc_TransactionNo');
    const vpcSecureHash = params.get('vpc_SecureHash');

    useEffect(() => {
        const finalizeBooking = async () => {
            if (txnResponseCode === '0') {
                try {
                    const response = await axios.post('/api/bookings/confirm', {
                        vpc_OrderInfo: bookingId,
                        vpc_TransactionNo: transactionNo,
                        vpc_TxnResponseCode: txnResponseCode,
                        vpc_SecureHash: vpcSecureHash,
                    });
                    setStatusMessage(response.data.message || 'Booking successful!');
                } catch (error) {
                    setStatusMessage('Failed to save booking: ' + (error.response?.data?.message || error.message));
                }
            } else {
                setStatusMessage('Transaction failed. Please try again.');
            }
        };

        finalizeBooking();
    }, [txnResponseCode, bookingId, transactionNo, vpcSecureHash]);

    return (
        <div style={{ textAlign: 'center', marginTop: '50px' }}>
            <h2>Booking Status</h2>
            <p>{statusMessage}</p>
        </div>
    );
};

export default BookingResult;
