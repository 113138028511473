import React, { useState, useEffect } from 'react';
import { Table, Input, Button, Rate, Popconfirm, message } from 'antd';
import axios from 'axios';

const { Search } = Input;

function ReviewManagementScreen() {
    const [reviews, setReviews] = useState([]);
    const [filteredReviews, setFilteredReviews] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [filterRating, setFilterRating] = useState(null);

    useEffect(() => {
        fetchReviews();
    }, []);

    // Fetch all reviews from the server
    const fetchReviews = async () => {
        setLoading(true);
        try {
            const response = await axios.get('/api/rooms/getallreviews');
            setReviews(response.data.reviews); // Set reviews in the state
            setFilteredReviews(response.data.reviews); // Show all reviews initially
        } catch (error) {
            message.error('Error fetching reviews');
        } finally {
            setLoading(false); // Set loading to false after fetching
        }
    };

    // Filter reviews by exact star rating
    const handleFilterByRating = (value) => {
        setFilterRating(value);
        const filtered = reviews.filter((review) => review.rating === value);
        setFilteredReviews(filtered);
    };

    // Filter reviews by keyword (in comment)
    const handleSearch = (value) => {
        setSearchKeyword(value);
        const filtered = reviews.filter((review) =>
            review.comment.toLowerCase().includes(value.toLowerCase())
        );
        setFilteredReviews(filtered);
    };

    // Reset all filters
    const resetFilters = () => {
        setFilteredReviews(reviews);
        setSearchKeyword('');
        setFilterRating(null);
    };

    // Delete a review
    const handleDeleteReview = async (roomId, reviewId) => {
        try {
            await axios.delete(`/api/rooms/deletereview/${roomId}/${reviewId}`);
            message.success('Review deleted successfully');
            fetchReviews(); // Refresh reviews after deletion
        } catch (error) {
            message.error('Error deleting review');
        }
    };

    // Table columns
    const columns = [
        {
            title: 'Room Name',
            dataIndex: 'roomName',
            key: 'roomName',
        },
        {
            title: 'Guest Name',
            dataIndex: 'guestName',
            key: 'guestName',
        },
        {
            title: 'Rating',
            dataIndex: 'rating',
            key: 'rating',
            render: (rating) => <Rate value={rating} disabled />,
        },
        {
            title: 'Comment',
            dataIndex: 'comment',
            key: 'comment',
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            render: (date) => new Date(date).toLocaleDateString(),
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure to delete this review?"
                    onConfirm={() => handleDeleteReview(record.roomId, record._id)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button type="primary">Delete</Button>
                </Popconfirm>
            ),
        },
    ];

    return (
        <div className="container">
            <h1>Review Management</h1>
            <div className="filter-section" style={{ marginBottom: '16px' }}>
                <Search
                    placeholder="Search by comment"
                    enterButton
                    onSearch={handleSearch}
                    value={searchKeyword}
                    onChange={(e) => setSearchKeyword(e.target.value)}
                    style={{ width: 300, marginRight: '16px' }}
                />
                <span style={{ marginRight: '8px' }}>Filter By Rating:</span>
                <Rate
                    allowClear
                    value={filterRating}
                    onChange={handleFilterByRating}
                    style={{ marginRight: '16px' }}
                />
                <Button onClick={resetFilters}>Reset Filters</Button>
            </div>
            <Table
                dataSource={filteredReviews}
                columns={columns}
                rowKey={(record) => record._id}
                loading={loading}
                pagination={{ pageSize: 10 }}
                scroll={{ x: 1000 }}
            />
        </div>
    );
}

export default ReviewManagementScreen;
