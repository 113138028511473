import React, { createContext, useState } from 'react';

// Create the BookingContext
export const BookingContext = createContext();

export const BookingProvider = ({ children }) => {
    const [bookingSidebar, setBookingSidebar] = useState([]);
    const [guestInfo, setGuestInfo] = useState({
        guestName: '',
        guestEmail: '',
        guestPhoneNumber: '',
        guestNationality: '',
        specialRequest: '',
    });
    const [paymentMethod, setPaymentMethod] = useState('creditCard'); // Default payment method is creditCard

    const updatePaymentMethod = (method) => {
        console.log(`Payment method updated: ${method}`); // Log whenever payment method is updated
        setPaymentMethod(method);
    };

    return (
        <BookingContext.Provider value={{
            bookingSidebar,
            setBookingSidebar,
            guestInfo,
            setGuestInfo,
            paymentMethod,
            setPaymentMethod: updatePaymentMethod
        }}>
            {children}
        </BookingContext.Provider>
    );
};
