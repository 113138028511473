import React, { createContext, useState, useEffect } from 'react';
import { fetchExchangeRates } from '../utils/currencyService';

export const CurrencyContext = createContext();

export const CurrencyProvider = ({ children }) => {
    const [currency, setCurrency] = useState('VND');
    const [rates, setRates] = useState(null);

    useEffect(() => {
        const loadRates = async () => {
            const fetchedRates = await fetchExchangeRates();
            setRates(fetchedRates);
        };
        loadRates();
    }, []);

    const convertAmount = (amount, targetCurrency) => {
        if (!rates) return amount;
        const rate = rates[targetCurrency] || 1; // Use `rates` for conversion
        return amount * rate;
    };

    return (
        <CurrencyContext.Provider value={{ currency, setCurrency, convertAmount }}>
            {children}
        </CurrencyContext.Provider>
    );
};
