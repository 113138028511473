//back to this
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

function RetrieveCardInputScreen() {
    const [bookingId, setBookingId] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const handleSubmit = (event) => {
        event.preventDefault();

        if (!bookingId || !password) {
            Swal.fire('Error', 'Please fill in both fields', 'error');
            return;
        }

        // Redirect to the decrypt-card route, passing the bookingId and password
        navigate(`/decrypt-card/${bookingId}`, { state: { password } });
    };

    return (
        <div className="container">
            <h2>Retrieve Card Details</h2>
            <form onSubmit={handleSubmit}>
                <div className="mb-3">
                    <label htmlFor="bookingId" className="form-label">
                        Booking ID
                    </label>
                    <input
                        type="text"
                        className="form-control"
                        id="bookingId"
                        placeholder="Enter your booking ID"
                        value={bookingId}
                        onChange={(e) => setBookingId(e.target.value)}
                        required
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="password" className="form-label">
                        Password
                    </label>
                    <input
                        type="password"
                        className="form-control"
                        id="password"
                        placeholder="Enter the decryption password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </div>
                <button type="submit" className="btn btn-primary mb-5">
                    Retrieve Card Details
                </button>
            </form>
        </div>
    );
}

export default RetrieveCardInputScreen;
