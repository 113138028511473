//back to this
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import Swal from 'sweetalert2';
import { Modal, Input, Form, Button, Select, Checkbox, Radio } from 'antd';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'; // For icons
import DatePicker from "react-datepicker"; // Use react-datepicker as an alternative
import "react-datepicker/dist/react-datepicker.css";

const { Option } = Select;

function RoomManagementScreen() {
    const [rooms, setRooms] = useState([]);
    const [availability, setAvailability] = useState({});
    const [rentPerDayByDate, setRentPerDayByDate] = useState({});
    const [ratePlans, setRatePlans] = useState({});
    const [closeout, setCloseout] = useState({});
    const [minimumStay, setMinimumStay] = useState({});
    const [maximumStay, setMaximumStay] = useState({});

    const [selectedDate, setSelectedDate] = useState(null);
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedRoomId, setSelectedRoomId] = useState(null);
    const [selectedRatePlanId, setSelectedRatePlanId] = useState(null);
    const [manualRate, setManualRate] = useState('');
    const [availableRooms, setAvailableRooms] = useState('');
    const [currentMonth, setCurrentMonth] = useState(moment());
    const [selectedYear, setSelectedYear] = useState(moment().year());
    const [showRestrictions, setShowRestrictions] = useState(false);


    // Bulk update state
    const [bulkModalVisible, setBulkModalVisible] = useState(false);
    const [bulkRoomId, setBulkRoomId] = useState(null);
    const [bulkRatePlanId, setBulkRatePlanId] = useState(null);
    const [bulkStartDate, setBulkStartDate] = useState(null);
    const [bulkEndDate, setBulkEndDate] = useState(null);
    const [bulkAvailability, setBulkAvailability] = useState('');
    const [bulkRate, setBulkRate] = useState('');
    const [bulkCloseout, setBulkCloseout] = useState(false);
    const [bulkMinimumStay, setBulkMinimumStay] = useState('');
    const [bulkMaximumStay, setBulkMaximumStay] = useState('');
    const [bulkRackRate, setBulkRackRate] = useState('');

    const formatCurrency = (number) => {
        return new Intl.NumberFormat('id-ID', { maximumFractionDigits: 0 }).format(number);
    };

    useEffect(() => {
        const fetchRooms = async () => {
            try {
                const response = await axios.get('/api/rooms/getallrooms');
                setRooms(response.data);

                const initAvailability = {};
                const initRentPerDayByDate = {};
                const initCloseout = {};
                const initMinimumStay = {};
                const initMaximumStay = {};

                response.data.forEach(room => {
                    initAvailability[room._id] = room.availability || {};
                    initRentPerDayByDate[room._id] = room.rentperdayByDate || {};
                    initCloseout[room._id] = room.closeout || {};
                    initMinimumStay[room._id] = room.minimumStay || {};
                    initMaximumStay[room._id] = room.maximumStay || {};
                });

                setAvailability(initAvailability);
                setRentPerDayByDate(initRentPerDayByDate);
                setCloseout(initCloseout);
                setMinimumStay(initMinimumStay);
                setMaximumStay(initMaximumStay);
            } catch (err) {
                console.error('Error fetching rooms:', err.message);
            }
        };

        const fetchRatePlans = async () => {
            try {
                const response = await axios.get('/api/rateplans/getallrateplans');
                const ratePlansByRoom = {};

                response.data.forEach(plan => {
                    plan.room.forEach(roomId => {
                        if (!ratePlansByRoom[roomId]) {
                            ratePlansByRoom[roomId] = [];
                        }
                        ratePlansByRoom[roomId].push(plan);
                    });
                });

                setRatePlans(ratePlansByRoom);
            } catch (err) {
                console.error('Error fetching rate plans:', err.message);
            }
        };

        fetchRooms();
        fetchRatePlans();
    }, []);

    // Function to handle manual rate change in the modal
    const handleManualRateChange = (e) => {
        const value = parseInt(e.target.value, 10);
        if (value > 0 || e.target.value === '') {
            setManualRate(e.target.value);
        } else {
            Swal.fire('Invalid Input', 'Rate must be greater than 0.', 'warning');
        }
    };

    const handleAvailabilityChange = (e) => {
        const value = parseInt(e.target.value, 10);
        setAvailableRooms(e.target.value);

        // Check if availability is 0 to close out the room automatically
        if (value === 0) {
            setCloseout(prevCloseout => ({
                ...prevCloseout,
                [selectedRoomId]: {
                    ...prevCloseout[selectedRoomId],
                    [selectedDate.format('DD-MM-YYYY')]: true,
                }
            }));
        } else {
            setCloseout(prevCloseout => ({
                ...prevCloseout,
                [selectedRoomId]: {
                    ...prevCloseout[selectedRoomId],
                    [selectedDate.format('DD-MM-YYYY')]: false,
                }
            }));
        }
    };

    const isDateClosedOutForRoom = (roomId, dateKey) => {
        return closeout[roomId]?.[dateKey] === true;
    };

    const handleCellClick = (roomId, date, ratePlanId = null, type = 'availability') => {
        setSelectedRoomId(roomId);
        setSelectedRatePlanId(ratePlanId);
        const formattedDate = moment(date, 'DD-MM-YYYY').format('DD-MM-YYYY');
        setSelectedDate(moment(formattedDate, 'DD-MM-YYYY'));

        // Check the type of cell clicked
        if (type === 'availability') {
            setAvailableRooms(availability[roomId][formattedDate] || '');
            setManualRate('');
        } else if (type === 'rate') {
            if (ratePlanId) {
                const ratePlan = ratePlans[roomId]?.find(plan => plan._id === ratePlanId);
                const ratePlanPricing = ratePlan?.rateplanpricing.find(rpp => rpp.roomId.toString() === roomId);

                if (ratePlanPricing) {
                    if (ratePlan.ratePlanSetup === 'Manual') {
                        setManualRate(ratePlanPricing.manualRate[formattedDate] || '');
                    } else if (ratePlan.ratePlanSetup === 'Derived') {
                        setManualRate(rentPerDayByDate[roomId][formattedDate] || '');
                    }
                }
            }
            setAvailableRooms('');
        } else if (type === 'minimumStay') {
            // Handle Minimum Stay input
            setManualRate('');
            setAvailableRooms('');
        }

        // Open modal after setting the values
        setModalVisible(true);
    };

    const handleModalOk = async () => {
        if (!selectedRoomId || !selectedDate) {
            console.error('No room or date selected');
            return;
        }

        const dateString = selectedDate.format('DD-MM-YYYY');

        try {
            const updatedAvailability = {
                ...availability[selectedRoomId],
                [dateString]: availableRooms || availability[selectedRoomId][dateString],
            };

            const updatedMinimumStay = {
                ...minimumStay[selectedRoomId],
                [dateString]: minimumStay[selectedRoomId][dateString] || 1,
            };


            const updatedCloseout = {
                ...closeout[selectedRoomId],
                [dateString]: closeout[selectedRoomId]?.[dateString] || false,
            };

            const updatedMaximumStay = {
                ...maximumStay[selectedRoomId],
                [dateString]: maximumStay[selectedRoomId]?.[dateString] || null,
            };


            if (selectedRatePlanId) {
                const ratePlan = ratePlans[selectedRoomId]?.find(plan => plan._id === selectedRatePlanId);
                if (ratePlan) {
                    const ratePlanPricing = ratePlan.rateplanpricing.find(rpp => rpp.roomId.toString() === selectedRoomId);

                    if (ratePlan.ratePlanSetup === 'Manual') {
                        const manualRateUpdate = {
                            [dateString]: manualRate || ratePlanPricing.manualRate[dateString]
                        };

                        await axios.put('/api/rateplans/updaterateplan', {
                            ratePlanId: selectedRatePlanId,
                            roomId: selectedRoomId,
                            manualRate: manualRateUpdate,
                        });

                        setRatePlans(prevRatePlans => {
                            const updatedRatePlans = { ...prevRatePlans };
                            const updatedPlan = updatedRatePlans[selectedRoomId].find(plan => plan._id === selectedRatePlanId);
                            const updatedPricing = updatedPlan.rateplanpricing.find(rpp => rpp.roomId.toString() === selectedRoomId);
                            updatedPricing.manualRate = {
                                ...updatedPricing.manualRate,
                                ...manualRateUpdate
                            };
                            return updatedRatePlans;
                        });
                    }
                }
            } else {
                const updatedRentPerDayByDate = {
                    ...rentPerDayByDate[selectedRoomId],
                    [dateString]: manualRate || rentPerDayByDate[selectedRoomId][dateString],
                };

                await axios.put('/api/rooms/updateroomavailability', {
                    roomid: selectedRoomId,
                    availability: updatedAvailability,
                    rentperdayByDate: updatedRentPerDayByDate,
                    closeout: updatedCloseout,  // Include closeout updates
                    minimumStay: updatedMinimumStay,
                    maximumStay: updatedMaximumStay,
                });

                setRentPerDayByDate(prev => ({
                    ...prev,
                    [selectedRoomId]: updatedRentPerDayByDate,
                }));
            }

            setAvailability(prev => ({
                ...prev,
                [selectedRoomId]: updatedAvailability,
            }));

            setMinimumStay(prev => ({
                ...prev,
                [selectedRoomId]: updatedMinimumStay,
            }));

            setMaximumStay(prev => ({
                ...prev,
                [selectedRoomId]: updatedMaximumStay,
            }));


            setCloseout(prev => ({
                ...prev,
                [selectedRoomId]: updatedCloseout,
            }));

            setModalVisible(false);
            Swal.fire('Success', 'Room availability, rates, minimum stay and closeout updated successfully!', 'success');
        } catch (err) {
            console.error('Error updating room or rate plan:', err.message);
            Swal.fire('Error', 'Failed to update room details', 'error');
        }
    };

    const handleModalCancel = () => {
        setModalVisible(false);
    };

    const handleCloseoutToggle = (roomId, date) => {
        const formattedDate = moment(date, 'DD-MM-YYYY').format('DD-MM-YYYY');
        const isClosedOut = !closeout[roomId]?.[formattedDate];

        // Apply the same logic as in the modal to update the closeout state
        const updatedCloseout = {
            ...closeout[roomId],
            [formattedDate]: isClosedOut,  // Toggle the closeout status
        };

        // Update the local closeout state
        setCloseout(prevCloseout => ({
            ...prevCloseout,
            [roomId]: updatedCloseout,
        }));

        // Save the closeout status to the backend
        saveCloseoutStatus(roomId, formattedDate, isClosedOut);
    };

    const saveCloseoutStatus = async (roomId, date, isClosedOut) => {
        try {
            const updatedCloseout = {
                ...closeout[roomId],
                [date]: isClosedOut
            };

            await axios.put('/api/rooms/updateroomavailability', {
                roomid: roomId,
                closeout: updatedCloseout
            });

            // Optionally update the local closeout state again after saving
            setCloseout(prev => ({
                ...prev,
                [roomId]: updatedCloseout,
            }));

            Swal.fire('Success', 'Closeout status updated successfully!', 'success');
        } catch (error) {
            console.error('Error updating closeout status:', error.message);
            Swal.fire('Error', 'Failed to update closeout status', 'error');
        }
    };

    const getDateColumns = () => {
        return Array.from({ length: moment(currentMonth).daysInMonth() }, (_, i) => {
            const day = i + 1;
            const date = moment(`${day}-${currentMonth.month() + 1}-${selectedYear}`, 'DD-MM-YYYY');
            // Only include dates from today onward
            return date.isSameOrAfter(moment(), 'day') ? date.format('DD MMM') : null;
        }).filter(date => date !== null);  // Filter out null values representing past dates
    };


    const dateColumns = getDateColumns();

    const nextMonth = () => {
        setCurrentMonth((prev) => {
            const next = prev.clone().add(1, 'month');
            setSelectedYear(next.year()); // Update the year based on the new month
            return next;
        });
    };


    const handleMonthYearChange = (date) => {
        if (date) {
            const selectedDate = moment(date);  // Convert date to moment object
            setCurrentMonth(selectedDate.startOf('month'));  // Use startOf('month') with moment
            setSelectedYear(selectedDate.year());
        }
    };

    const prevMonth = () => {
        setCurrentMonth((prev) => {
            const prevDate = prev.clone().subtract(1, 'month');
            setSelectedYear(prevDate.year()); // Update the year based on the new month
            return prevDate;
        });
    };

    const openBulkModal = () => {
        setBulkModalVisible(true);
    };

    const handleBulkModalOk = async () => {
        if (!bulkRoomId) {
            Swal.fire('Error', 'Please select a room', 'error');
            return;
        }

        const bulkRateValue = parseInt(bulkRate, 10);
        const bulkRackRateValue = parseInt(bulkRackRate, 10);
        const bulkAvailabilityValue = parseInt(bulkAvailability, 10);

        if (bulkRate && bulkRateValue <= 0) {
            Swal.fire('Invalid Input', 'Rate must be greater than 0.', 'warning');
            return;
        }

        if (bulkRackRate && bulkRackRateValue <= 0) {
            Swal.fire('Invalid Input', 'Rack rate must be greater than 0.', 'warning');
            return;
        }

        const updatedAvailabilityByDate = {};
        const updatedRatesByDate = {};
        const updatedCloseoutByDate = {};  // New object to store closeout data
        const updatedMinimumStayByDate = {};
        const updatedMaximumStayByDate = {};
        const updatedRackRateByDate = {}; // Define updatedManualRateByDate for rate plan manual rates

        const start = moment(bulkStartDate, 'YYYY-MM-DD');
        const end = moment(bulkEndDate, 'YYYY-MM-DD');

        for (let date = start.clone(); date.isBefore(end.clone().add(1, 'days')); date.add(1, 'days')) {
            const dateString = date.format('DD-MM-YYYY');
            updatedAvailabilityByDate[dateString] = bulkAvailability || availability[bulkRoomId][dateString];
            updatedMinimumStayByDate[dateString] = bulkMinimumStay || minimumStay[bulkRoomId][dateString];
            updatedMaximumStayByDate[dateString] = bulkMaximumStay || maximumStay[bulkRoomId]?.[dateString] || null;

            updatedRackRateByDate[dateString] = bulkRackRate || rentPerDayByDate[bulkRoomId][dateString];
            updatedCloseoutByDate[dateString] = bulkAvailabilityValue === 0;

            if (bulkRatePlanId) {
                const ratePlan = ratePlans[bulkRoomId]?.find(plan => plan._id === bulkRatePlanId);
                if (ratePlan && ratePlan.ratePlanSetup === 'Manual') {
                    updatedRatesByDate[dateString] = bulkRate;
                } else {
                    Swal.fire('Error', 'Cannot update derived rates in bulk', 'error');
                    return;
                }
            } else {
                updatedRatesByDate[dateString] = bulkRate || rentPerDayByDate[bulkRoomId][dateString];
            }

            // Handle optional closeout (only set if user selects Open/Close)
            if (bulkCloseout !== null && bulkCloseout !== undefined) {
                updatedCloseoutByDate[dateString] = bulkCloseout;
            }
        }

        try {
            // PUT request to update availability, rates, and closeout status
            await axios.put('/api/rooms/updateroomavailability', {
                roomid: bulkRoomId,
                availability: updatedAvailabilityByDate || {},  // Ensure object is valid
                rentperdayByDate: updatedRackRateByDate,  // Ensure object is valid
                closeout: updatedCloseoutByDate || {},  // Ensure object is valid
                minimumStay: updatedMinimumStayByDate, // Add minimum stay for bulk
                maximumStay: updatedMaximumStayByDate,
            });

            setRentPerDayByDate(prev => ({
                ...prev,
                [bulkRoomId]: {
                    ...prev[bulkRoomId],
                    ...updatedRackRateByDate,
                },
            }));

            setAvailability(prev => ({
                ...prev,
                [bulkRoomId]: {
                    ...prev[bulkRoomId],
                    ...updatedAvailabilityByDate,
                },
            }));

            setMinimumStay(prev => ({
                ...prev,
                [bulkRoomId]: {
                    ...prev[bulkRoomId],
                    ...updatedMinimumStayByDate,
                },
            }));

            setMaximumStay(prev => ({
                ...prev,
                [bulkRoomId]: {
                    ...prev[bulkRoomId],
                    ...updatedMaximumStayByDate,
                },
            }));

            // Update closeout state only if closeout was selected
            if (bulkCloseout !== null && bulkCloseout !== undefined) {
                setCloseout(prev => ({
                    ...prev,
                    [bulkRoomId]: {
                        ...prev[bulkRoomId],
                        ...updatedCloseoutByDate,
                    },
                }));
            }

            // Update the state with the new availability, rates, and closeout statuses
            if (bulkRatePlanId && ratePlans[bulkRoomId]?.find(plan => plan._id === bulkRatePlanId)?.ratePlanSetup === 'Manual') {
                await axios.put('/api/rateplans/updaterateplan', {
                    ratePlanId: bulkRatePlanId,
                    roomId: bulkRoomId,
                    manualRate: updatedRatesByDate,
                });

                setRatePlans(prevRatePlans => {
                    const updatedRatePlans = { ...prevRatePlans };
                    const updatedPlan = updatedRatePlans[bulkRoomId].find(plan => plan._id === bulkRatePlanId);
                    const updatedPricing = updatedPlan.rateplanpricing.find(rpp => rpp.roomId.toString() === bulkRoomId);
                    updatedPricing.manualRate = {
                        ...updatedPricing.manualRate,
                        ...updatedRatesByDate,
                    };
                    return updatedRatePlans;
                });
            }

            Swal.fire('Success', 'Bulk update successful!', 'success');
            setBulkModalVisible(false);
        } catch (error) {
            console.error('Error applying bulk update:', error.message);
            Swal.fire('Error', 'Failed to apply bulk update', 'error');
        }
    };



    const handleBulkModalCancel = () => {
        setBulkModalVisible(false);
    };

    return (
        <div className='container main-calendar'>
            <h1>Manage Rate & Availability</h1>

            <div className="calendar-container mb-3">
                <Button className="button-select" onClick={openBulkModal} style={{ marginLeft: '10px' }}>
                    Bulk Update
                </Button>
                <Button className="button-select" onClick={() => setShowRestrictions(!showRestrictions)} style={{ marginLeft: '10px' }}>
                    {showRestrictions ? 'Hide Restrictions' : 'Restrictions'}
                </Button>
                <Button className="button-select" onClick={prevMonth}>
                    <FaChevronLeft /> {/* React-icons left arrow */}
                </Button>
                <Button className="button-select" onClick={nextMonth}>
                    <FaChevronRight /> {/* React-icons right arrow */}
                </Button>
                <DatePicker
                    selected={currentMonth.toDate()}  // Convert moment to Date object
                    onChange={handleMonthYearChange}
                    dateFormat="MMM YYYY"  // Format month and year
                    showMonthYearPicker  // Enable month-year picker only
                    className="month-year-picker"
                />


            </div>

            <div className="table-container">
                <table className="room-table">
                    <thead>
                        <tr>
                            <th className="sticky-column">Room</th>
                            {dateColumns.map(date => {
                                const day = moment(date, 'DD MMM').day(); // Get the day of the week (0 = Sunday, 6 = Saturday)
                                const isSaturday = day === 6;
                                const isSunday = day === 0;

                                return (
                                    <th
                                        key={date}
                                        className={isSaturday || isSunday ? 'weekend-highlight' : ''}
                                        style={{ textAlign: 'center' }}
                                    >
                                        {date}
                                    </th>
                                );
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {rooms.map((room, index) => (
                            <React.Fragment key={room._id}>
                                {/* Add a divider row between room types */}
                                {index > 0 && (
                                    <tr className="room-divider">
                                        <td colSpan={dateColumns.length + 2} style={{ height: '15px' }}></td> {/* Adjust the height as needed */}
                                    </tr>
                                )}

                                <tr>
                                    <tr className="sticky-room-name" rowSpan={4 + (ratePlans[room._id]?.length || 0)}>
                                        <strong>{room.name}</strong>
                                    </tr>
                                </tr>

                                {/* Availability row */}
                                <tr>
                                    <td className="sticky-column"><strong>Availability</strong></td>
                                    {dateColumns.map(date => {
                                        const day = moment(date, 'DD MMM').date();
                                        const dateKey = moment(`${day}-${currentMonth.month() + 1}-${selectedYear}`, 'DD-MM-YYYY').format('DD-MM-YYYY');
                                        const availabilityForDate = availability[room._id][dateKey] || 0;
                                        const isClosedOut = closeout[room._id]?.[dateKey];
                                        return (
                                            <td key={`${room._id}-${date}-availability`}
                                                className={`availability-cell ${isClosedOut ? 'closed-out' : ''}`}
                                                onClick={!isClosedOut ? () => handleCellClick(room._id, dateKey) : undefined}
                                                style={{ cursor: isClosedOut ? 'not-allowed' : 'pointer', backgroundColor: isDateClosedOutForRoom(room._id, dateKey) ? '#f4e736' : 'transparent' }}>
                                                <div className="availability-box">{isClosedOut ? 'Closed' : availabilityForDate}</div>
                                            </td>
                                        );
                                    })}
                                </tr>

                                {showRestrictions && (
                                    <tr>
                                        {/* Closeout row */}
                                        <td className="sticky-column"><strong>Closeout</strong></td>
                                        {dateColumns.map(date => {
                                            const day = moment(date, 'DD MMM').date();
                                            const dateKey = moment(`${day}-${currentMonth.month() + 1}-${selectedYear}`, 'DD-MM-YYYY').format('DD-MM-YYYY');
                                            const isClosedOut = closeout[room._id]?.[dateKey] || false;

                                            return (
                                                <td
                                                    key={`${room._id}-${date}-closeout`}
                                                    className={`availability-cell ${isClosedOut ? 'closed-out' : ''}`}
                                                    style={{ textAlign: 'center', backgroundColor: isDateClosedOutForRoom(room._id, dateKey) ? '#f4e736' : 'transparent' }}
                                                >
                                                    <Checkbox
                                                        checked={isClosedOut}
                                                        onChange={() => handleCloseoutToggle(room._id, dateKey)}
                                                    />
                                                </td>
                                            );
                                        })}
                                    </tr>
                                )}

                                {/* Minimum Stay row */}
                                {showRestrictions && (
                                    <tr>
                                        <td className="sticky-column"><strong>Min Stay</strong></td>
                                        {dateColumns.map(date => {
                                            const day = moment(date, 'DD MMM').date();
                                            const dateKey = moment(`${day}-${currentMonth.month() + 1}-${selectedYear}`, 'DD-MM-YYYY').format('DD-MM-YYYY');
                                            const minStayForDate = minimumStay[room._id]?.[dateKey] || 1;
                                            return (
                                                <td key={`${room._id}-${date}-minimumstay`}
                                                    className="availability-cell"
                                                    onClick={() => handleCellClick(room._id, dateKey, null, 'minimumStay')}
                                                    style={{ cursor: 'pointer', backgroundColor: isDateClosedOutForRoom(room._id, dateKey) ? '#f4e736' : 'transparent' }}>
                                                    <div>{minStayForDate}</div>
                                                </td>
                                            );
                                        })}
                                    </tr>
                                )}

                                {showRestrictions && (
                                    <tr>
                                        <td className="sticky-column"><strong>Max Stay</strong></td>
                                        {dateColumns.map(date => {
                                            const day = moment(date, 'DD MMM').date();
                                            const dateKey = moment(`${day}-${currentMonth.month() + 1}-${selectedYear}`, 'DD-MM-YYYY').format('DD-MM-YYYY');
                                            const maxStayForDate = maximumStay[room._id]?.[dateKey] || ''; // Fetch the maximum stay for the date

                                            return (
                                                <td key={`${room._id}-${date}-maximumstay`}
                                                    className="availability-cell"
                                                    onClick={() => handleCellClick(room._id, dateKey, null, 'maximumStay')} // Handle cell click
                                                    style={{ cursor: 'pointer', backgroundColor: isDateClosedOutForRoom(room._id, dateKey) ? '#f4e736' : 'transparent' }}>
                                                    <div>{maxStayForDate || 'N/A'}</div>
                                                </td>
                                            );
                                        })}
                                    </tr>
                                )}

                                {/* Rack Rate row */}
                                <tr>
                                    <td className="sticky-column"><strong>Rack Rate</strong></td>
                                    {dateColumns.map(date => {
                                        const day = moment(date, 'DD MMM').date();
                                        const dateKey = moment(`${day}-${currentMonth.month() + 1}-${selectedYear}`, 'DD-MM-YYYY').format('DD-MM-YYYY');
                                        const rentForDate = rentPerDayByDate[room._id][dateKey] || 0;
                                        return (
                                            <td key={`${room._id}-${date}-rent`}
                                                className="availability-cell"
                                                onClick={() => handleCellClick(room._id, dateKey)}
                                                style={{ cursor: 'pointer', backgroundColor: isDateClosedOutForRoom(room._id, dateKey) ? '#f4e736' : 'transparent' }}>
                                                <div>{formatCurrency(rentForDate)}</div>
                                            </td>
                                        );
                                    })}
                                </tr>

                                {/* Room rate plans */}
                                {ratePlans[room._id]?.map(plan => (
                                    <tr key={plan._id}>
                                        <td className="sticky-column">{plan.rateplanname}</td>
                                        {dateColumns.map(date => {
                                            const day = moment(date, 'DD MMM').date();
                                            const dateKey = moment(`${day}-${currentMonth.month() + 1}-${selectedYear}`, 'DD-MM-YYYY').format('DD-MM-YYYY');
                                            let rateForDate = rentPerDayByDate[room._id][dateKey] || 0;

                                            if (plan.ratePlanSetup === 'Derived') {
                                                rateForDate = rentPerDayByDate[room._id][dateKey] || '';
                                            } else if (plan.ratePlanSetup === 'Manual') {
                                                rateForDate = plan.rateplanpricing.find(rpp => rpp.roomId.toString() === room._id)?.manualRate[dateKey] || '';
                                            }

                                            return (
                                                <td key={`${room._id}-${date}-rateplan-${plan._id}`}
                                                    className={`availability-cell ${plan.ratePlanSetup === 'Derived' ? 'derived-rate' : ''}`}
                                                    onClick={() => handleCellClick(room._id, dateKey, plan._id)}
                                                    style={{ cursor: 'pointer', backgroundColor: isDateClosedOutForRoom(room._id, dateKey) ? '#f4e736' : 'transparent' }}>
                                                    <div>
                                                        {formatCurrency(rateForDate)}
                                                        {plan.ratePlanSetup === 'Derived' && <span className="link-symbol">🔗</span>}
                                                    </div>
                                                </td>
                                            );
                                        })}
                                    </tr>
                                ))}
                            </React.Fragment>
                        ))}
                    </tbody>

                </table>
            </div>

            {modalVisible && (
                <Modal
                    title={`YOUR ACTION ON ${selectedDate.format('DD-MM-YYYY')}`}
                    visible={modalVisible}
                    onOk={handleModalOk}
                    onCancel={handleModalCancel}
                >
                    <Form layout="vertical">
                        {/* Input for Available Rooms (optional) */}
                        {!selectedRatePlanId && (
                            <Form.Item label="Available Rooms">
                                <Input
                                    type="number"
                                    value={availableRooms}
                                    onChange={handleAvailabilityChange}
                                    placeholder="Enter available rooms (optional)"
                                />
                            </Form.Item>
                        )}

                        {/* Input for Rate (optional) */}
                        <Form.Item label={selectedRatePlanId ? "Rate for the Selected Date" : "Rent Per Day"}>
                            <Input
                                type="number"
                                value={manualRate}
                                onChange={handleManualRateChange}
                                disabled={selectedRatePlanId && ratePlans[selectedRoomId]?.find(plan => plan._id === selectedRatePlanId)?.ratePlanSetup !== 'Manual'}
                                placeholder="Enter rate (optional)"
                            />
                        </Form.Item>

                        {/* Input for Minimum Stay (optional) */}
                        <Form.Item label="Minimum Stay">
                            <Input
                                type="number"
                                value={minimumStay[selectedRoomId]?.[selectedDate.format('DD-MM-YYYY')] || ''}
                                onChange={(e) => setMinimumStay({
                                    ...minimumStay,
                                    [selectedRoomId]: {
                                        ...minimumStay[selectedRoomId],
                                        [selectedDate.format('DD-MM-YYYY')]: e.target.value
                                    }
                                })}
                                placeholder="Enter minimum stay (optional)"
                            />
                        </Form.Item>

                        <Form.Item label="Maximum Stay">
                            <Input
                                type="number"
                                value={maximumStay[selectedRoomId]?.[selectedDate.format('DD-MM-YYYY')] || ''}
                                onChange={(e) => setMaximumStay({
                                    ...maximumStay,
                                    [selectedRoomId]: {
                                        ...maximumStay[selectedRoomId],
                                        [selectedDate.format('DD-MM-YYYY')]: e.target.value
                                    }
                                })}
                                placeholder="Enter maximum stay (optional)"
                            />
                        </Form.Item>

                        {/* Checkbox for Closeout */}
                        <Form.Item label="Closeout">
                            <Checkbox
                                checked={closeout[selectedRoomId]?.[selectedDate.format('DD-MM-YYYY')] || false}
                                onChange={(e) => setCloseout({
                                    ...closeout,
                                    [selectedRoomId]: {
                                        ...closeout[selectedRoomId],
                                        [selectedDate.format('DD-MM-YYYY')]: e.target.checked
                                    }
                                })}
                            >
                                Close out this date
                            </Checkbox>
                        </Form.Item>
                    </Form>
                </Modal>
            )}

            {bulkModalVisible && (
                <Modal
                    title="Bulk Update Rates and Availability"
                    visible={bulkModalVisible}
                    onOk={handleBulkModalOk}
                    onCancel={handleBulkModalCancel}
                >
                    <Form layout="vertical">
                        <Form.Item label="Select Room">
                            <Select
                                value={bulkRoomId}
                                onChange={(value) => setBulkRoomId(value)}
                            >
                                {rooms.map(room => (
                                    <Option key={room._id} value={room._id}>
                                        {room.name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>

                        <Form.Item label="Select Rate Plan">
                            <Select
                                value={bulkRatePlanId}
                                onChange={(value) => setBulkRatePlanId(value)}
                                allowClear
                            >
                                {ratePlans[bulkRoomId]?.map(plan => (
                                    <Option key={plan._id} value={plan._id}>
                                        {plan.rateplanname}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>

                        <Form.Item label="Rate (For Rate Plan)">
                            <Input
                                type="number"
                                value={bulkRate}
                                onChange={(e) => setBulkRate(e.target.value)}
                                disabled={bulkRatePlanId && ratePlans[bulkRoomId]?.find(plan => plan._id === bulkRatePlanId)?.ratePlanSetup !== 'Manual'}
                                placeholder="Enter rate for rate plan (if applicable)"
                            />
                        </Form.Item>

                        {/* Independent Rack Rate (Optional) */}
                        <Form.Item label="Rack Rate (Optional)">
                            <Input
                                type="number"
                                value={bulkRackRate}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    if (parseInt(value, 10) > 0 || value === '') {
                                        setBulkRackRate(value);
                                    } else {
                                        Swal.fire('Invalid Input', 'Rack rate must be greater than 0.', 'warning');
                                    }
                                }}
                                placeholder="Enter rack rate (optional)"
                            />
                        </Form.Item>

                        <Form.Item label="Start Date">
                            <Input
                                type="date"
                                value={bulkStartDate}
                                onChange={(e) => setBulkStartDate(e.target.value)}
                            />
                        </Form.Item>

                        <Form.Item label="End Date">
                            <Input
                                type="date"
                                value={bulkEndDate}
                                onChange={(e) => setBulkEndDate(e.target.value)}
                            />
                        </Form.Item>

                        <Form.Item label="Availability">
                            <Input
                                type="number"
                                value={bulkAvailability}
                                onChange={(e) => setBulkAvailability(e.target.value)}
                            />
                        </Form.Item>

                        <Form.Item label="Minimum Stay">
                            <Input
                                type="number"
                                value={bulkMinimumStay}
                                onChange={(e) => setBulkMinimumStay(e.target.value)}
                            />
                        </Form.Item>

                        <Form.Item label="Maximum Stay">
                            <Input
                                type="number"
                                value={bulkMaximumStay}
                                onChange={(e) => setBulkMaximumStay(e.target.value)}
                                placeholder="Enter maximum stay (optional)"
                            />
                        </Form.Item>

                        <Form.Item label="Status">
                            <Radio.Group
                                value={bulkCloseout !== null ? (bulkCloseout ? 'close' : 'open') : null}
                                onChange={(e) => setBulkCloseout(e.target.value === 'close' ? true : e.target.value === 'open' ? false : null)}
                            >
                                <Radio value={null}>No Change</Radio>
                                <Radio value="open">Open these dates</Radio>
                                <Radio value="close">Closeout these dates</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Form>
                </Modal>
            )}
        </div>
    );
}

export default RoomManagementScreen;
