import React from 'react';
import { Link } from 'react-router-dom'; // Add this line;

function Landingscreen() {
    return (
        <div className='row landing d-flex align-items-center justify-content-center' style={{ height: '100vh' }}>
            <div className='col-md-12 text-center'>
                <h1 style={{ color: 'white', fontSize: '85px' }}>Syncera</h1>
                <h6 className='text-white' style={{ fontSize: '18px' }}>From Click to Comfort</h6>
                <Link to='/home'>
                    <button className='btn landingbtn'>Get Started</button>
                </Link>
            </div>
        </div>
    )
}

export default Landingscreen
