import React, { useState, useEffect } from 'react';
import { Form, Button, Input, message, Slider } from 'antd';
import axios from 'axios';

const initialBrandingState = {
    pageBackgroundImage: '',
    roomNameColor: '#443d80',
    buttonColor: '#1c2389',
    navbarColor: '#ffffff',
    footerColor: '#433d80',
    starRatingColor: '#f8c94a',
    dragBarColor: '#1c2389',
    navbarMenuColor: '#847aff',
    searchBarGradient: 'linear-gradient(135deg, rgba(150, 95, 190, 0.645), rgba(78, 43, 87, 0.175))',
    headingColor: '#000000',
    paragraphColor: '#333333',
    priceTagColor: '#ff0000',
};

const parseGradient = (gradient) => {
    const regex = /rgba\((\d+), (\d+), (\d+), ([\d.]+)\)/g;
    const matches = [...gradient.matchAll(regex)];

    if (matches.length === 2) {
        return {
            start: {
                color: `#${parseInt(matches[0][1]).toString(16).padStart(2, '0')}${parseInt(matches[0][2]).toString(16).padStart(2, '0')}${parseInt(matches[0][3]).toString(16).padStart(2, '0')}`,
                opacity: parseFloat(matches[0][4]),
            },
            end: {
                color: `#${parseInt(matches[1][1]).toString(16).padStart(2, '0')}${parseInt(matches[1][2]).toString(16).padStart(2, '0')}${parseInt(matches[1][3]).toString(16).padStart(2, '0')}`,
                opacity: parseFloat(matches[1][4]),
            }
        };
    }

    // Default colors if parsing fails
    return {
        start: { color: '#965fbe', opacity: 0.645 },
        end: { color: '#4e2b57', opacity: 0.175 },
    };
};

const BrandingScreen = () => {
    const [branding, setBranding] = useState(initialBrandingState);
    const [gradientStart, setGradientStart] = useState({ color: '#965fbe', opacity: 0.645 });
    const [gradientEnd, setGradientEnd] = useState({ color: '#4e2b57', opacity: 0.175 });

    useEffect(() => {
        const fetchBranding = async () => {
            try {
                const response = await axios.get(`/api/branding/getallbranding`);
                const data = response.data || initialBrandingState;
                setBranding(data);

                // Parse the gradient colors and opacities
                const parsedGradient = parseGradient(data.searchBarGradient);
                setGradientStart(parsedGradient.start);
                setGradientEnd(parsedGradient.end);
            } catch (error) {
                console.error('Error fetching branding settings:', error);
                message.error('Failed to fetch branding settings.');
            }
        };
        fetchBranding();
    }, []);


    useEffect(() => {
        const gradientString = `linear-gradient(135deg, rgba(${parseInt(gradientStart.color.slice(1, 3), 16)}, ${parseInt(gradientStart.color.slice(3, 5), 16)}, ${parseInt(gradientStart.color.slice(5), 16)}, ${gradientStart.opacity}), rgba(${parseInt(gradientEnd.color.slice(1, 3), 16)}, ${parseInt(gradientEnd.color.slice(3, 5), 16)}, ${parseInt(gradientEnd.color.slice(5), 16)}, ${gradientEnd.opacity}))`;
        setBranding((prev) => ({ ...prev, searchBarGradient: gradientString }));
    }, [gradientStart, gradientEnd]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setBranding((prev) => ({ ...prev, [name]: value }));
    };


    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setBranding((prev) => ({
            ...prev,
            pageBackgroundImageFile: file,
            pageBackgroundImage: URL.createObjectURL(file),
        }));
    };

    const handleSave = async () => {
        try {
            const formData = new FormData();
            Object.keys(branding).forEach((key) => {
                if (key !== 'pageBackgroundImageFile') {
                    formData.append(key, branding[key]);
                }
            });

            if (branding.pageBackgroundImageFile) {
                formData.append('backgroundImage', branding.pageBackgroundImageFile);
            }

            const response = await axios.post('/api/branding/create', formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });

            setBranding(response.data);
            message.success('Branding settings saved successfully!');
        } catch (error) {
            console.error('Error saving branding settings:', error);
            message.error('Failed to save branding settings.');
        }
    };

    return (
        <div className="container">
            <h1>Branding Settings</h1>
            <Form layout="vertical">
                <Form.Item label="Page Background Image">
                    <input type="file" onChange={handleFileChange} accept="image/*" />
                    {branding.pageBackgroundImage && (
                        <img src={branding.pageBackgroundImage} alt="Background Preview" style={{ width: '100%', marginTop: '10px' }} />
                    )}
                </Form.Item>
                <Form.Item label="Room Name Color">
                    <Input type="color" name="roomNameColor" value={branding.roomNameColor} onChange={handleInputChange} />
                </Form.Item>
                <Form.Item label="Button Color">
                    <Input type="color" name="buttonColor" value={branding.buttonColor} onChange={handleInputChange} />
                </Form.Item>
                <Form.Item label="Navbar Color">
                    <Input type="color" name="navbarColor" value={branding.navbarColor} onChange={handleInputChange} />
                </Form.Item>
                <Form.Item label="Footer Color">
                    <Input type="color" name="footerColor" value={branding.footerColor} onChange={handleInputChange} />
                </Form.Item>
                <Form.Item label="Star Rating Color">
                    <Input type="color" name="starRatingColor" value={branding.starRatingColor} onChange={handleInputChange} />
                </Form.Item>
                <Form.Item label="Drag Bar Color">
                    <Input type="color" name="dragBarColor" value={branding.dragBarColor} onChange={handleInputChange} />
                </Form.Item>
                <Form.Item label="Navbar Menu Color">
                    <Input type="color" name="navbarMenuColor" value={branding.navbarMenuColor} onChange={handleInputChange} />
                </Form.Item>
                <Form.Item label="Search Bar Gradient - Start Color">
                    <Input
                        type="color"
                        value={gradientStart.color}
                        onChange={(e) => setGradientStart((prev) => ({ ...prev, color: e.target.value }))}
                    />
                    <Slider
                        min={0}
                        max={1}
                        step={0.01}
                        value={gradientStart.opacity}
                        onChange={(value) => setGradientStart((prev) => ({ ...prev, opacity: value }))}
                        tooltip={{ formatter: (value) => `Opacity: ${Math.round(value * 100)}%` }}
                    />
                </Form.Item>

                <Form.Item label="Search Bar Gradient - End Color">
                    <Input
                        type="color"
                        value={gradientEnd.color}
                        onChange={(e) => setGradientEnd((prev) => ({ ...prev, color: e.target.value }))}
                    />
                    <Slider
                        min={0}
                        max={1}
                        step={0.01}
                        value={gradientEnd.opacity}
                        onChange={(value) => setGradientEnd((prev) => ({ ...prev, opacity: value }))}
                        tooltip={{ formatter: (value) => `Opacity: ${Math.round(value * 100)}%` }}
                    />
                </Form.Item>

                {/* Gradient Preview */}
                <div
                    className="searchbar-preview"
                    style={{
                        marginTop: '10px',
                        height: '50px',
                        borderRadius: '8px',
                        background: branding.searchBarGradient,
                    }}
                ></div>
                <Form.Item className='mt-3' label="Heading Color">
                    <Input type="color" name="headingColor" value={branding.headingColor} onChange={handleInputChange} />
                </Form.Item>
                <Form.Item label="Paragraph Color">
                    <Input type="color" name="paragraphColor" value={branding.paragraphColor} onChange={handleInputChange} />
                </Form.Item>
                <Form.Item label="Price Tag Color">
                    <Input type="color" name="priceTagColor" value={branding.priceTagColor} onChange={handleInputChange} />
                </Form.Item>
                <Button className='mt-3' type="primary" onClick={handleSave}>Save</Button>
            </Form>
        </div>
    );
};

export default BrandingScreen;
