import React, { useState, useEffect } from 'react';
import { Table, Input, Button, DatePicker, Modal, message, Form, Tooltip, Select } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import axios from 'axios';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import 'antd/dist/reset.css';

const { Option } = Select;

function BookingListScreen() {
    const [bookings, setBookings] = useState([]);
    const [filteredBookings, setFilteredBookings] = useState([]);
    const [rooms, setRooms] = useState([]);
    const [ratePlans, setRatePlans] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedBooking, setSelectedBooking] = useState(null);
    const [form] = Form.useForm();
    const navigate = useNavigate();

    useEffect(() => {
        fetchBookings();
        fetchRooms();
        fetchRatePlans();
    }, []);

    const fetchBookings = async () => {
        try {
            const response = await axios.get('/api/bookings/getallbookings');
            setBookings(response.data);
            setFilteredBookings(response.data);
        } catch (error) {
            message.error('Failed to fetch bookings.');
        }
    };

    const fetchRooms = async () => {
        try {
            const response = await axios.get('/api/rooms/getallrooms');
            setRooms(response.data);
        } catch (error) {
            message.error('Failed to fetch rooms.');
        }
    };

    const fetchRatePlans = async () => {
        try {
            const response = await axios.get('/api/rateplans/getallrateplans');
            setRatePlans(response.data);
        } catch (error) {
            message.error('Failed to fetch rate plans.');
        }
    };

    const showModal = (record) => {
        setSelectedBooking(record);
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setSelectedBooking(null);
    };

    const handleFilterSubmit = (allValues) => {
        const { bookingId, startDate, endDate, room, ratePlan, transactionId, search } = allValues;

        let filtered = bookings;

        // Filter by Booking ID (if provided)
        if (bookingId) {
            filtered = filtered.filter(booking => booking.bookingId.includes(bookingId));
        }

        // Filter by Start Date (if provided)
        if (startDate) {
            const start = startDate.format('DD-MM-YYYY');
            filtered = filtered.filter(booking => booking.fromdate === start);
        }

        // Filter by End Date (Check-Out Date) (if provided)
        if (endDate) {
            const end = endDate.format('DD-MM-YYYY');
            filtered = filtered.filter(booking => booking.todate === end);
        }

        // Filter by Room (if provided)
        if (room) {
            filtered = filtered.filter(booking => booking.rooms.some(r => r.room === room));
        }

        // Filter by Rate Plan (if provided)
        if (ratePlan) {
            filtered = filtered.filter(booking => booking.rooms.some(r => r.ratePlanName === ratePlan));
        }

        // Filter by Transaction ID (if provided)
        if (transactionId) {
            filtered = filtered.filter(booking => booking.transactionId.includes(transactionId));
        }

        // Filter by Search (Guest Name, Guest Email, Guest Phone Number)
        if (search) {
            const searchLower = search.toLowerCase();
            filtered = filtered.filter(booking =>
                booking.guestName.toLowerCase().includes(searchLower) ||
                booking.guestEmail.toLowerCase().includes(searchLower) ||
                booking.guestPhoneNumber.toLowerCase().includes(searchLower)
            );
        }

        // Set the filtered bookings
        setFilteredBookings(filtered);
    };

    const handleResetFilters = () => {
        form.resetFields(); // Reset all form fields
        setFilteredBookings(bookings); // Reset the filtered bookings to show all
    };

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
        message.success('Booking ID copied to clipboard');
    };

    const columns = [
        {
            title: 'Booking ID',
            dataIndex: 'bookingId',
            key: 'bookingId',
            render: (bookingId) => (
                <div>
                    {bookingId}
                    <Tooltip title="Copy Booking ID">
                        <CopyOutlined
                            style={{ marginLeft: 3, color: '#1890ff', cursor: 'pointer' }}
                            onClick={() => copyToClipboard(bookingId)}
                        />
                    </Tooltip>
                </div>
            ),
        },
        {
            title: 'Room Booked',
            dataIndex: 'rooms',
            key: 'rooms',
            render: (rooms) => rooms.map(room => room.room).join(', ')
        },
        {
            title: 'Check-In',
            dataIndex: 'fromdate',
            key: 'fromdate',
        },
        {
            title: 'Check-Out',
            dataIndex: 'todate',
            key: 'todate'
        },
        { title: 'Guest Name', dataIndex: 'guestName', key: 'guestName' },
        {
            title: 'Total Amount',
            dataIndex: 'totalamount',
            key: 'totalamount',
            render: (totalamount) => <strong>{totalamount}</strong>, // Bold the total amount
        },
        {
            title: 'Transaction ID',
            dataIndex: 'transactionId',
            key: 'transactionId',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (status) => {
                let color = '';
                let text = '';

                switch (status) {
                    case 'Booked':
                        color = '#52c41a'; // Green for booked
                        text = 'Booked';
                        break;
                    case 'Cancelled':
                        color = '#ff4d4f'; // Red for cancelled
                        text = 'Cancelled';
                        break;
                    default:
                        color = '#faad14'; // Yellow for other statuses
                        text = status;
                        break;
                }

                return <span style={{ color }}>{text}</span>;
            },
        },
        {
            title: 'Action',
            key: 'action',
            render: (record) => (
                <div>
                    <Button onClick={() => showModal(record)} style={{ marginRight: 8 }}>
                        View Details
                    </Button>
                    <Button type="link" onClick={() => navigate('/admin/retrieve-card-input')}>
                        Retrieve Card
                    </Button>
                </div>
            ),
        },
    ];

    return (
        <div className="container">
            <h1>Bookings</h1>
            <Form
                form={form}
                layout="vertical"
                onFinish={handleFilterSubmit}
            >
                <div className="row mb-2">
                    <div className="col-md-12">
                        <Form.Item name="search" label={<strong>Search by Guest Name, Email, Phone</strong>}>
                            <Input placeholder="Guest Name, Email, Phone" />
                        </Form.Item>
                    </div>
                    <div className="col-md-6">
                        <Form.Item name="bookingId" label={<strong>Filter by Booking ID</strong>}>
                            <Input placeholder="Booking ID" />
                        </Form.Item>
                    </div>
                    <div className="col-md-6">
                        <Form.Item name="transactionId" label={<strong>Filter by Transaction ID</strong>}>
                            <Input placeholder="Transaction ID" />
                        </Form.Item>
                    </div>
                    <div className="col-md-6">
                        <Form.Item name="startDate" label={<strong>Filter by Start Date</strong>}>
                            <DatePicker format="DD-MM-YYYY" />
                        </Form.Item>
                    </div>
                    <div className="col-md-6">
                        <Form.Item name="endDate" label={<strong>Filter by End Date</strong>}>
                            <DatePicker format="DD-MM-YYYY" />
                        </Form.Item>
                    </div>
                    <div className="col-md-6">
                        <Form.Item name="room" label={<strong>Filter by Room</strong>}>
                            <Select placeholder="Select Room">
                                {rooms.map((room) => (
                                    <Option key={room._id} value={room.name}>
                                        {room.name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </div>
                    <div className="col-md-6">
                        <Form.Item name="ratePlan" label={<strong>Filter by Rate Plan</strong>}>
                            <Select placeholder="Select Rate Plan">
                                {ratePlans.map((plan) => (
                                    <Option key={plan._id} value={plan.rateplanname}>
                                        {plan.rateplanname}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </div>
                </div>
                <Button type="primary" htmlType="submit" block>
                    Submit Filters
                </Button>
                <Button type="default" onClick={handleResetFilters} block style={{ marginTop: '10px' }}>
                    Reset Filters
                </Button>
            </Form>

            <Table
                dataSource={filteredBookings}
                columns={columns}
                rowKey="_id"
                pagination={{ pageSize: 10 }}
                scroll={{ x: 1000 }} // Enable horizontal scrolling

            />

            {selectedBooking && (
                <Modal
                    title={<h2 style={{ color: '#1890ff' }}>Booking Details</h2>}
                    visible={isModalVisible}
                    onCancel={handleCancel}
                    footer={null}
                    width={800}
                    bodyStyle={{ padding: '20px', backgroundColor: '#f9f9f9' }}
                >
                    <p><strong>Booking ID:</strong> <span style={{ color: '#1890ff' }}>{selectedBooking.bookingId}</span></p>
                    <p><strong>User ID:</strong> {selectedBooking.userid}</p>
                    <p><strong>Guest Name:</strong> {selectedBooking.guestName}</p>
                    <p><strong>Guest Email:</strong> {selectedBooking.guestEmail}</p>
                    <p><strong>Guest Phone Number:</strong> {selectedBooking.guestPhoneNumber}</p>
                    <p><strong>Guest Nationality:</strong> {selectedBooking.guestNationality}</p>
                    <p><strong>Check-In Date:</strong> {selectedBooking.fromdate}</p>
                    <p><strong>Check-Out Date:</strong> {selectedBooking.todate}</p>
                    <p><strong>Total Amount:</strong> <span style={{ color: '#52c41a' }}>{selectedBooking.totalamount}</span></p>
                    <p><strong>Total Days:</strong> {selectedBooking.totaldays}</p>
                    <p><strong>Status:</strong> <span style={{ color: selectedBooking.status === 'Booked' ? '#52c41a' : '#ff4d4f' }}>{selectedBooking.status}</span></p>

                    <h3>Room Details:</h3>
                    {selectedBooking.rooms.map((room, index) => (
                        <div key={room._id} style={{ marginBottom: '15px', padding: '10px', border: '1px solid #e8e8e8', borderRadius: '4px' }}>
                            <p><strong>Room {index + 1}:</strong> {room.room}</p>
                            <p><strong>Rate Plan Name:</strong> {room.ratePlanName}</p>
                            <p><strong>Rate Plan Rate:</strong> {room.ratePlanRate}</p>
                            <p><strong>Number of Rooms:</strong> {room.numberOfRooms}</p>
                            <p><strong>Adults:</strong> {room.adults}</p>
                            <p><strong>Children:</strong> {room.children}</p>
                            <p><strong>Extra Bed:</strong> {room.extraBed ? 'Yes' : 'No'}</p>
                            <p><strong>Meal Plan:</strong> {room.mealPlan}</p>
                            <p><strong>Cancellation Policy:</strong> {room.cancellationPolicy}</p>
                        </div>
                    ))}

                    <p><strong>Transaction ID:</strong> {selectedBooking.transactionId}</p>
                    <p><strong>Special Request:</strong> {selectedBooking.specialRequest}</p>
                    <p><strong>Payment Method:</strong> {selectedBooking.paymentMethod}</p>
                    <p><strong>Addon Services:</strong>
                        {selectedBooking.addonServices.map(service => (
                            <div key={service._id}>
                                {service.serviceName} - {service.servicePrice} - {service.serviceDescription}
                            </div>
                        ))}
                    </p>
                    <p><strong>Created At:</strong> {moment(selectedBooking.createdAt).format('DD-MM-YYYY HH:mm')}</p>
                    <p><strong>Updated At:</strong> {moment(selectedBooking.updatedAt).format('DD-MM-YYYY HH:mm')}</p>
                </Modal>
            )}
        </div>
    );
}

export default BookingListScreen;
