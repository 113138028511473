import React, { useState, useEffect } from 'react';
import { Button, Container, Row, Col } from 'react-bootstrap';
import { FaFacebookF, FaTwitter, FaInstagram } from 'react-icons/fa';
import { RiSecurePaymentLine, RiLockPasswordFill, RiShieldCheckFill } from 'react-icons/ri';
import { SiTripadvisor } from 'react-icons/si';
import axios from 'axios';

const Footer = () => {
    const [hotelInfo, setHotelInfo] = useState({
        facebook: '',
        instagram: '',
        twitter: '',
        tripadvisor: '',
        description: '',
        logo: '',
    });

    const [footerColor, setFooterColor] = useState('#433d80');

    useEffect(() => {
        const fetchHotelInfo = async () => {
            try {
                const response = await axios.get('/api/hotelInfo/getallinfo');
                const { socialMediaLinks, description, logo } = response.data;
                setHotelInfo({
                    ...socialMediaLinks,
                    description,
                    logo,
                });
            } catch (error) {
                console.error('Error fetching hotel info:', error);
            }
        };

        const fetchBranding = async () => {
            try {
                const response = await axios.get('/api/branding/getallbranding');
                setFooterColor(response.data.footerColor || '#433d80'); // Use fetched color or default
            } catch (error) {
                console.error('Error fetching branding info:', error);
            }
        };

        fetchHotelInfo();
        fetchBranding();
    }, []);

    // Limit description to 100 words
    const trimmedDescription = hotelInfo.description
        ? hotelInfo.description.split(' ').slice(0, 20).join(' ') + '...'
        : "We are creating high-quality resources and tools to aid developers during the development of their projects.";

    return (
        <footer className="footer text-light py-6" style={{ backgroundColor: footerColor }}>
            <Container>
                <Row>
                    <Col md={6} className="mb-2">
                        <a href="/home" className="d-flex align-items-center text-light" style={{ textDecoration: 'none' }}>
                            <img alt="logo" src={hotelInfo.logo || '/whitelogo2.png'} width="150px" />
                        </a>
                        <p className="my-3" style={{ width: '350px' }}>{trimmedDescription}</p>
                        <div className="mt-4">
                            {hotelInfo.facebook && (
                                <a href={hotelInfo.facebook} target="_blank" rel="noopener noreferrer">
                                    <Button variant="link" className="text-light me-2">
                                        <FaFacebookF />
                                    </Button>
                                </a>
                            )}
                            {hotelInfo.twitter && (
                                <a href={hotelInfo.twitter} target="_blank" rel="noopener noreferrer" className="mx-3">
                                    <Button variant="link" className="text-light">
                                        <FaTwitter />
                                    </Button>
                                </a>
                            )}
                            {hotelInfo.instagram && (
                                <a href={hotelInfo.instagram} target="_blank" rel="noopener noreferrer">
                                    <Button variant="link" className="text-light">
                                        <FaInstagram />
                                    </Button>
                                </a>
                            )}
                            {hotelInfo.tripadvisor && (
                                <a href={hotelInfo.tripadvisor} target="_blank" rel="noopener noreferrer" className="ms-3">
                                    <Button variant="link" className="text-light">
                                        <SiTripadvisor />
                                    </Button>
                                </a>
                            )}
                        </div>
                    </Col>
                    <Col md={2} className="mt-3">
                        <p className="h5" style={{ fontWeight: '600' }}>Legal</p>
                        <ul className="list-unstyled">
                            <li><a href="/cookie-settings" className="text-light text-decoration-none">Cookie Settings</a></li>
                            <li><a href="/terms-of-service" className="text-light text-decoration-none">Terms of Service</a></li>
                            <li><a href="/privacy-policy" className="text-light text-decoration-none">Privacy Policy</a></li>
                        </ul>
                    </Col>
                    <Col md={2} className="mt-3">
                        <p className="h5" style={{ fontWeight: '600' }}>Help</p>
                        <ul className="list-unstyled">
                            <li><a href="/support" className="text-light text-decoration-none">Support</a></li>
                            <li><a href="/sign-up" className="text-light text-decoration-none">Sign Up</a></li>
                            <li><a href="/sign-in" className="text-light text-decoration-none">Sign In</a></li>
                        </ul>
                    </Col>
                    <Col md={2} className="mt-3 mb-3 d-flex align-items-start justify-content-center">
                        <div className="text-right">
                            <RiLockPasswordFill className="text-light me-2" size={30} title="SHA-256 Encryption" />
                            <RiSecurePaymentLine className="text-light me-2" size={30} title="Book Securely" />
                            <RiShieldCheckFill className="text-light" size={30} title="PCI DSS Compliant" />
                        </div>
                    </Col>
                </Row>
                <small className="text-center d-block">&copy; Chiic World Vietnam Jsc, 2024. All rights reserved.</small>
            </Container>

        </footer>
    );
};

export default Footer;
