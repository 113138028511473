import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Rate } from 'antd';

function GeneralInfoScreen() {
    const [initialHotelInfo, setInitialHotelInfo] = useState(null);
    const [hotelInfo, setHotelInfo] = useState({
        hotelName: '',
        starRating: 1,
        email: '',
        phoneNumber: '',
        address: '',
        mapLocation: '',
        factsheet: null,
        featureImage: null,
        logo: null,
        favicon: null,
        description: '',
        socialMediaLinks: {
            facebook: '',
            instagram: '',
            tripadvisor: '',
            officialWebsite: ''
        },
        currency: 'USD',
        preferredLanguage: 'English'
    });
    const [message, setMessage] = useState('');

    useEffect(() => {
        const fetchHotelInfo = async () => {
            try {
                const response = await axios.get('/api/hotelInfo/getallinfo');
                const data = response.data || {};
                setHotelInfo(data);
                setInitialHotelInfo(data);
            } catch (error) {
                console.error('Error fetching hotel info:', error);
            }
        };
        fetchHotelInfo();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setHotelInfo({ ...hotelInfo, [name]: value });
    };

    const handleRateChange = (value) => {
        setHotelInfo({ ...hotelInfo, starRating: value });
    };

    const handleSocialMediaChange = (e) => {
        const { name, value } = e.target;
        setHotelInfo({
            ...hotelInfo,
            socialMediaLinks: { ...hotelInfo.socialMediaLinks, [name]: value }
        });
    };

    const handleFileChange = (e) => {
        const { name, files } = e.target;
        if (name === 'logo' && files[0] && files[0].type !== 'image/png') {
            setMessage('Only PNG files are allowed for the logo.');
            return;
        }
        setHotelInfo({ ...hotelInfo, [name]: files[0] });
    };

    const updateHotelInfo = async () => {
        try {
            const formData = new FormData();
            Object.keys(hotelInfo).forEach((key) => {
                if (key === 'socialMediaLinks') {
                    Object.keys(hotelInfo[key]).forEach((subKey) => {
                        formData.append(`socialMediaLinks[${subKey}]`, hotelInfo[key][subKey]);
                    });
                } else {
                    formData.append(key, hotelInfo[key]);
                }
            });
            const response = await axios.post('/api/hotelInfo/create', formData, {
                headers: { 'Content-Type': 'multipart/form-data' }
            });
            setHotelInfo(response.data);
            setInitialHotelInfo(response.data);

            Swal.fire({
                title: 'Success!',
                text: 'Hotel information updated successfully!',
                icon: 'success',
                timer: 2000,
                showConfirmButton: false
            }).then(() => {
                window.location.href = '/home'; // Redirect to Home after upated successfully
            });
        } catch (error) {
            console.error('Error updating hotel info:', error);
            setMessage('Failed to update hotel information.');
        }
    };

    const discardChanges = () => {
        setHotelInfo(initialHotelInfo);
        setMessage('Changes discarded.');
    };

    return (
        <div className="container">
            <h1>Manage Hotel Information</h1>
            {message && <p>{message}</p>}

            <form className="row">
                <div className="col-md-6">
                    <label className="mt-2"><strong>Hotel Name:</strong></label>
                    <input
                        type="text"
                        name="hotelName"
                        value={hotelInfo.hotelName}
                        onChange={handleChange}
                        className="form-control"
                    />

                    <label className="mt-2"><strong>Hotel Description:</strong></label>
                    <textarea
                        name="description"
                        value={hotelInfo.description}
                        onChange={handleChange}
                        className="form-control"
                        maxLength="500"
                        placeholder="Enter a short description for SEO and social media sharing."
                    ></textarea>

                    <label className="mt-2"><strong>Email:</strong></label>
                    <input
                        type="email"
                        name="email"
                        value={hotelInfo.email}
                        onChange={handleChange}
                        className="form-control"
                    />

                    <label className="mt-2"><strong>Phone Number:</strong></label>
                    <input
                        type="tel"
                        name="phoneNumber"
                        value={hotelInfo.phoneNumber}
                        onChange={handleChange}
                        className="form-control"
                    />

                    <label className="mt-2"><strong>Address:</strong></label>
                    <input
                        type="text"
                        name="address"
                        value={hotelInfo.address}
                        onChange={handleChange}
                        className="form-control"
                    />

                    <label className="mt-2"><strong>Map Location (URL):</strong></label>
                    <input
                        type="url"
                        name="mapLocation"
                        value={hotelInfo.mapLocation}
                        onChange={handleChange}
                        className="form-control"
                    />

                    <label className="mt-2"><strong>Social Media Links:</strong></label>
                    <input
                        type="url"
                        name="facebook"
                        placeholder="Facebook URL"
                        value={hotelInfo?.socialMediaLinks?.facebook}
                        onChange={handleSocialMediaChange}
                        className="form-control"
                    />
                    <input
                        type="url"
                        name="instagram"
                        placeholder="Instagram URL"
                        value={hotelInfo?.socialMediaLinks?.instagram}
                        onChange={handleSocialMediaChange}
                        className="form-control"
                    />
                    <input
                        type="url"
                        name="tripadvisor"
                        placeholder="Tripadvisor URL"
                        value={hotelInfo?.socialMediaLinks?.tripadvisor}
                        onChange={handleSocialMediaChange}
                        className="form-control"
                    />
                    <input
                        type="url"
                        name="officialWebsite"
                        placeholder="Official Website URL"
                        value={hotelInfo?.socialMediaLinks?.officialWebsite}
                        onChange={handleSocialMediaChange}
                        className="form-control"
                    />
                </div>

                <div className="col-md-6">
                    <label className="mt-2"><strong>Star Rating:</strong></label>
                    <br />
                    <Rate
                        value={hotelInfo.starRating}
                        onChange={handleRateChange}
                        allowHalf
                        className="mb-2 mt-2"
                    />
                    <br />

                    <label className="mt-2"><strong>Factsheet (PDF):</strong></label>
                    <input
                        type="file"
                        name="factsheet"
                        onChange={handleFileChange}
                        className="form-control"
                    />

                    <label className="mt-2"><strong>Feature Image (for social media and SEO):</strong></label>
                    {hotelInfo.featureImage && (
                        <div>
                            <img src={hotelInfo.featureImage} alt="Current Feature" style={{ maxWidth: '100px', margin: '10px 0' }} />
                        </div>
                    )}
                    <input
                        type="file"
                        name="featureImage"
                        onChange={handleFileChange}
                        className="form-control"
                    />

                    <label className="mt-2"><strong>Hotel Logo (PNG only):</strong></label>
                    {hotelInfo.logo && (
                        <div>
                            <img src={hotelInfo.logo} alt="Current Logo" style={{ maxWidth: '100px', margin: '10px 0' }} />
                        </div>
                    )}
                    <input
                        type="file"
                        name="logo"
                        accept="image/png"
                        onChange={handleFileChange}
                        className="form-control"
                    />

                    <label className="mt-2"><strong>Hotel Favicon (PNG only, square):</strong></label>
                    {hotelInfo.favicon && (
                        <div>
                            <img src={hotelInfo.favicon} alt="Current Favicon" style={{ maxWidth: '32px', margin: '10px 0' }} />
                        </div>
                    )}
                    <input
                        type="file"
                        name="favicon"
                        accept="image/png"
                        onChange={handleFileChange}
                        className="form-control"
                    />

                    <label className="mt-2"><strong>Currency:</strong></label>
                    <select
                        name="currency"
                        value={hotelInfo.currency}
                        onChange={handleChange}
                        className="form-control"
                    >
                        <option value="USD">USD</option>
                        <option value="EUR">EUR</option>
                    </select>

                    <label className="mt-2"><strong>Preferred Language:</strong></label>
                    <select
                        name="preferredLanguage"
                        value={hotelInfo.preferredLanguage}
                        onChange={handleChange}
                        className="form-control"
                    >
                        <option value="English">English</option>
                        <option value="Spanish">Spanish</option>
                    </select>
                </div>

                <div className="col-12 d-flex justify-content-end mt-3">
                    <button type="button" onClick={updateHotelInfo} className="btn btn-primary me-2">
                        Update
                    </button>
                    <button type="button" onClick={discardChanges} className="btn btn-secondary">
                        Discard Changes
                    </button>
                </div>
            </form>
        </div>
    );
}

export default GeneralInfoScreen;
