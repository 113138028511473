import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Form, Input, Button, Select, message } from 'antd';

const { Option } = Select;

const HotelRulesScreen = () => {
    const [rulesData, setRulesData] = useState({
        checkInTimeFrom: '',
        checkOutTimeBefore: '',
        earlyCheckInCharge: 0,
        lateCheckOutCharge: 0,
        smokingRule: '',
        petAllow: null,
        damageCompensation: '',
        houseKeepingPolicy: '',
        noShowCharge: 0,
        lostAndFoundPolicy: '',
        refundPolicy: '',
        forceMajeureEvent: '',
        hotelRule: '',
    });
    const [isCreating, setIsCreating] = useState(false);

    useEffect(() => {
        fetchHotelRules();
    }, []);

    const fetchHotelRules = async () => {
        try {
            const response = await axios.get('/api/hotelrules/get');
            if (response.data && Object.keys(response.data).length > 0) {
                setRulesData(response.data);
                setIsCreating(false); // Rules already exist, no need to create
            } else {
                setIsCreating(true); // No rules exist, show create option
            }
        } catch (error) {
            console.error('Error fetching hotel rules:', error);
            message.error('Failed to fetch hotel rules.');
        }
    };

    const handleInputChange = (field, value) => {
        setRulesData({ ...rulesData, [field]: value });
    };

    const handleSaveRules = async () => {
        try {
            if (isCreating) {
                // Create new rules
                await axios.post('/api/hotelrules/create', rulesData);
                message.success('Hotel rules created successfully!');
                setIsCreating(false);
            } else {
                // Update existing rules
                await axios.put('/api/hotelrules/update', rulesData);
                message.success('Hotel rules updated successfully!');
            }
        } catch (error) {
            console.error('Error saving hotel rules:', error);
            message.error('Failed to save hotel rules.');
        }
    };

    return (
        <div className="container">
            <h1>Manage Hotel Rules</h1>
            <Form layout="vertical">
                <Form.Item label="Check-In Time From">
                    <Input
                        type="time"
                        value={rulesData.checkInTimeFrom}
                        onChange={(e) => handleInputChange('checkInTimeFrom', e.target.value)}
                    />
                </Form.Item>

                <Form.Item label="Check-Out Time Before">
                    <Input
                        type="time"
                        value={rulesData.checkOutTimeBefore}
                        onChange={(e) => handleInputChange('checkOutTimeBefore', e.target.value)}
                    />
                </Form.Item>

                <Form.Item label="Early Check-In Charge">
                    <Input
                        type="number"
                        value={rulesData.earlyCheckInCharge}
                        onChange={(e) => handleInputChange('earlyCheckInCharge', e.target.value)}
                    />
                </Form.Item>

                <Form.Item label="Late Check-Out Charge">
                    <Input
                        type="number"
                        value={rulesData.lateCheckOutCharge}
                        onChange={(e) => handleInputChange('lateCheckOutCharge', e.target.value)}
                    />
                </Form.Item>

                <Form.Item label="Smoking Rule">
                    <Input
                        value={rulesData.smokingRule}
                        onChange={(e) => handleInputChange('smokingRule', e.target.value)}
                    />
                </Form.Item>

                <Form.Item label="Pet Allowed">
                    <Select
                        value={rulesData.petAllow}
                        onChange={(value) => handleInputChange('petAllow', value)}
                    >
                        <Option value={true}>Yes</Option>
                        <Option value={false}>No</Option>
                    </Select>
                </Form.Item>

                <Form.Item label="Damage Compensation">
                    <Input
                        value={rulesData.damageCompensation}
                        onChange={(e) => handleInputChange('damageCompensation', e.target.value)}
                    />
                </Form.Item>

                <Form.Item label="House Keeping Policy">
                    <Input
                        value={rulesData.houseKeepingPolicy}
                        onChange={(e) => handleInputChange('houseKeepingPolicy', e.target.value)}
                    />
                </Form.Item>

                <Form.Item label="No Show Charge (%)">
                    <Input
                        type="number"
                        value={rulesData.noShowCharge}
                        onChange={(e) => handleInputChange('noShowCharge', e.target.value)}
                    />
                </Form.Item>

                <Form.Item label="Lost and Found Policy">
                    <Input
                        value={rulesData.lostAndFoundPolicy}
                        onChange={(e) => handleInputChange('lostAndFoundPolicy', e.target.value)}
                    />
                </Form.Item>

                <Form.Item label="Refund Policy">
                    <Input
                        value={rulesData.refundPolicy}
                        onChange={(e) => handleInputChange('refundPolicy', e.target.value)}
                    />
                </Form.Item>

                <Form.Item label="Force Majeure Event">
                    <Input
                        value={rulesData.forceMajeureEvent}
                        onChange={(e) => handleInputChange('forceMajeureEvent', e.target.value)}
                    />
                </Form.Item>

                <Form.Item label="Hotel Rule">
                    <Input
                        value={rulesData.hotelRule}
                        onChange={(e) => handleInputChange('hotelRule', e.target.value)}
                    />
                </Form.Item>

                <Button type="primary" onClick={handleSaveRules}>
                    {isCreating ? 'Create Rules' : 'Save Changes'}
                </Button>
            </Form>
        </div>
    );
};

export default HotelRulesScreen;
