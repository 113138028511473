
//back to this
import React, { useEffect, useState } from "react";
import { Tabs } from 'antd';
import axios from "axios";
import Swal from 'sweetalert2'
import { Tag } from 'antd';



function Profilescreen() {

    const user = JSON.parse(localStorage.getItem("currentUser"))
    useEffect(() => {
        if (!user) {
            window.location.href = '/login'
        }
    });
    return (
        <div className="mt-5 ms-5">
            <Tabs
                defaultActiveKey="1"
                items={[
                    {
                        label: 'Profile',
                        key: '1',
                        children: (
                            <>
                                <h1>My Profile</h1>
                                <br />
                                <p>Name: {user.name}</p>
                                <p>Email: {user.email}</p>
                                <p>isAdmin: {user.isAdmin ? 'Yes' : 'No'}</p>
                            </>
                        ),
                    },
                    {
                        label: 'Bookings',
                        key: '2',
                        children: <MyBookings />
                    },
                ]}
            />
        </div>
    )

}

export default Profilescreen

export function MyBookings() {
    const user = JSON.parse(localStorage.getItem("currentUser"));
    const [bookings, setBookings] = useState([])

    useEffect(() => {
        async function fetchBookings() {
            try {
                const response = await axios.post('/api/bookings/getbookingsbyuserid', { userid: user._id });
                setBookings(response.data);
            } catch (error) {
                console.log(error);
            }
        }
        fetchBookings();
    }, [user._id]); // Add 'user._id' to the dependency array

    async function cancelBooking(bookingid, roomid) {

        try {
            const result = await axios.post('/api/bookings/cancelbooking', { bookingid, roomid });
            console.log(result);
            Swal.fire({
                title: 'Success',
                text: 'Congratulations, your reservation was cancelled successfully',
                icon: 'success',
                confirmButtonText: 'OK'
            }).then(result => {
                window.location.reload()
            })
        } catch (error) {
            console.log(error);
            Swal.fire('Oops, Something went wrong', 'error')
        }
    }
    return (
        <div>
            <h1>My Bookings</h1>
            {bookings.length > 0 ? (
                <ul>
                    {bookings.map((booking) => (
                        <div className="row">
                            <div className="col-md-6 bs">
                                <div className="d-flex flex-column">
                                    <div className="d-flex justify-content-between align-items-end">
                                        <div>
                                            <h1>{booking.room}</h1>
                                            <p><strong>Booking ID: </strong> {booking._id}</p>
                                            <p><strong>Check In: </strong>{booking.fromdate}</p>
                                            <p><strong>Check Out: </strong>{booking.todate}</p>
                                            <p><strong>Total Amount: </strong>{booking.totalamount}</p>
                                            <p><strong>Total Day: </strong>{booking.totaldays}</p>
                                            <p><strong>Status: </strong> {booking.status === 'cancelled' ? (<Tag color="orange">CANCELLED</Tag>) : (<Tag color="green">CONFIRMED</Tag>)}</p>
                                            {/* Add more booking details as needed */}
                                        </div>
                                        {booking.status !== 'cancelled' && (<button className="btn btn-primary mb-2" type="button" onClick={() => cancelBooking(booking._id, booking.roomid)}>
                                            Cancel Booking
                                        </button>)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </ul>
            ) : (
                <p>No bookings found.</p>
            )}
        </div>
    );
}  
