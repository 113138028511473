import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Form, Button, Modal, Input, Select, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import Swal from 'sweetalert2';

const { Option } = Select;

const PoliciesScreen = () => {
    const [policies, setPolicies] = useState([]);
    const [extraBedPrice, setExtraBedPrice] = useState(0);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [editingPolicy, setEditingPolicy] = useState(null);
    const [formData, setFormData] = useState({
        cancellationRule: [{ chargeType: 'Flexible', daysBeforeCheckin: 0, chargePercentage: 0 }],
        childrenRule: { maxAge: 0, freeStay: true, additionalChildCharge: 0 }
    });

    useEffect(() => {
        fetchPoliciesAndExtraBedPrice();
    }, []);

    const fetchPoliciesAndExtraBedPrice = async () => {
        try {
            const policyResponse = await axios.get('/api/policies/getallpolicies');
            setPolicies(policyResponse.data);

            const extraBedPriceResponse = await axios.get('/api/extrabedprice/get');
            setExtraBedPrice(extraBedPriceResponse.data.price);
        } catch (error) {
            console.error(error);
            message.error('Failed to fetch policies or extra bed price.');
        }
    };

    const handleCancellationRuleChange = (field, value) => {
        const updatedCancellationRule = { ...formData.cancellationRule[0], [field]: value };
        setFormData({ ...formData, cancellationRule: [updatedCancellationRule] });
    };

    const handleChildrenRuleChange = (field, value) => {
        setFormData({ ...formData, childrenRule: { ...formData.childrenRule, [field]: value } });
    };

    const handleModalOk = async () => {
        try {
            if (editingPolicy) {
                await axios.put(`/api/policies/update/${editingPolicy._id}`, formData);
                message.success('Policy updated successfully');
            } else {
                await axios.post('/api/policies/create', formData);
                message.success('Policy created successfully');
            }
            setIsModalVisible(false);
            fetchPoliciesAndExtraBedPrice();
        } catch (error) {
            message.error('Failed to save policy');
        }
    };

    const handleCreate = () => {
        setEditingPolicy(null);
        setFormData({
            cancellationRule: [{ chargeType: 'Flexible', daysBeforeCheckin: 0, chargePercentage: 0 }],
            childrenRule: { maxAge: 0, freeStay: true, additionalChildCharge: 0 }
        });
        setIsModalVisible(true);
    };

    const handleEdit = (policy) => {
        setEditingPolicy(policy);
        setFormData(policy);
        setIsModalVisible(true);
    };

    const handleDelete = async (policyId) => {
        try {
            await axios.delete(`/api/policies/delete/${policyId}`);
            message.success('Policy deleted successfully');
            fetchPoliciesAndExtraBedPrice();
        } catch (error) {
            message.error('Failed to delete policy');
        }
    };

    const handleExtraBedPriceSubmit = async () => {
        try {
            await axios.post('/api/extrabedprice/set', { price: extraBedPrice });
            Swal.fire('Success!', 'Extra bed price has been updated.', 'success');
        } catch (error) {
            Swal.fire('Error!', 'Failed to update extra bed price.', 'error');
        }
    };

    const columns = [
        {
            title: 'Cancellation Rule',
            dataIndex: 'cancellationRule',
            key: 'cancellationRule',
            render: (rule) =>
                rule.length > 0
                    ? `${rule[0].chargeType} - ${rule[0].daysBeforeCheckin} days - ${rule[0].chargePercentage}%`
                    : 'No rule'
        },
        {
            title: 'Children Rule',
            dataIndex: 'childrenRule',
            key: 'childrenRule',
            render: (rule) =>
                rule
                    ? `Max Age: ${rule.maxAge}, Free Stay: ${rule.freeStay ? 'Yes' : `Charge: ${rule.additionalChildCharge}`}`
                    : 'No rule'
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (text, record) => (
                <>
                    <Button onClick={() => handleEdit(record)} type="primary" style={{ marginRight: 8 }}>
                        Edit
                    </Button>
                    <Button onClick={() => handleDelete(record._id)} type="link">
                        Delete
                    </Button>
                </>
            )
        }
    ];

    return (
        <div className='container'>
            <h1>Policies Management</h1>
            <Button type="primary" icon={<PlusOutlined />} onClick={handleCreate} style={{ marginBottom: 16 }}>
                Create Cancellation Policy
            </Button>

            <Table dataSource={policies} columns={columns} rowKey="_id" pagination={{ pageSize: 10 }} />
            <Modal
                title={editingPolicy ? 'Edit Policy' : 'Create Policy'}
                visible={isModalVisible}
                onOk={handleModalOk}
                onCancel={() => setIsModalVisible(false)}
            >
                <Form layout="vertical">
                    {/* Cancellation Policy */}
                    <Form.Item
                        label="Is there a period when the guest can cancel free of charge?"
                        required
                    >
                        <Select
                            value={formData.cancellationRule[0].chargeType}
                            onChange={(value) => handleCancellationRuleChange('chargeType', value)}
                        >
                            <Option value="Flexible">Flexible</Option>
                            <Option value="Non-refundable">Non-refundable</Option>
                        </Select>
                    </Form.Item>

                    {formData.cancellationRule[0].chargeType === 'Flexible' && (
                        <Form.Item
                            label="How many day(s) before arrival can the guest cancel free of charge?"
                            required
                        >
                            <Input
                                type="number"
                                value={formData.cancellationRule[0].daysBeforeCheckin}
                                onChange={(e) => handleCancellationRuleChange('daysBeforeCheckin', e.target.value)}
                            />
                        </Form.Item>
                    )}

                    <Form.Item
                        label="How much is the guest charged for cancellation (in %)?"
                        required
                    >
                        <Input
                            type="number"
                            value={formData.cancellationRule[0].chargePercentage}
                            onChange={(e) => handleCancellationRuleChange('chargePercentage', e.target.value)}
                        />
                    </Form.Item>

                    {/* Children Rule */}
                    <h4>Children Rule</h4>

                    <Form.Item
                        label="Max Age for Free Stay"
                        required
                    >
                        <Input
                            type="number"
                            value={formData.childrenRule.maxAge}
                            onChange={(e) => handleChildrenRuleChange('maxAge', e.target.value)}
                        />
                    </Form.Item>

                    <Form.Item
                        label="Free Stay"
                        required
                    >
                        <Select
                            value={formData.childrenRule.freeStay}
                            onChange={(value) => handleChildrenRuleChange('freeStay', value)}
                        >
                            <Option value={true}>Yes</Option>
                            <Option value={false}>No</Option>
                        </Select>
                    </Form.Item>

                    {!formData.childrenRule.freeStay && (
                        <Form.Item
                            label="Additional Child Charge"
                            required
                        >
                            <Input
                                type="number"
                                value={formData.childrenRule.additionalChildCharge}
                                onChange={(e) => handleChildrenRuleChange('additionalChildCharge', e.target.value)}
                            />
                        </Form.Item>
                    )}
                </Form>
            </Modal>


            <div className='mt-2 mb-5'>
                <h2>Extra Bed Price</h2>
                <Input
                    type="number"
                    value={extraBedPrice}
                    onChange={(e) => setExtraBedPrice(e.target.value)}
                    style={{ width: '200px', marginRight: '10px' }}
                />
                <Button type="primary" onClick={handleExtraBedPriceSubmit}>
                    Update Price
                </Button>
            </div>
        </div>
    );
};

export default PoliciesScreen;
