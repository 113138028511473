import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Button, Modal, Form, Input, Select, DatePicker, Upload, message } from 'antd';
import { PlusOutlined, UploadOutlined } from '@ant-design/icons';
import moment from 'moment';

const { Option } = Select;
const { RangePicker } = DatePicker;

const AddOnServiceScreen = () => {
    const [addOnServices, setAddOnServices] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [editingService, setEditingService] = useState(null);
    const [fileList, setFileList] = useState([]); // State to manage file list
    const [form] = Form.useForm();

    // Fetch all Add-On Services
    const fetchAddOnServices = async () => {
        try {
            setLoading(true);
            const response = await axios.get('/api/addonservices/getalladdonservices');
            setAddOnServices(response.data);
            setLoading(false);
        } catch (error) {
            console.error('Failed to fetch Add-On Services:', error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchAddOnServices();
    }, []);

    const openModal = (service = null) => {
        setEditingService(service);
        if (service) {
            form.setFieldsValue({
                ...service,
                dateApplied: [moment(service.dateApplied.startDate), moment(service.dateApplied.endDate)],
            });
        } else {
            form.resetFields();
        }
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setEditingService(null);
        setFileList([]); // Reset the file list when closing the modal
    };

    const handleDelete = async (id) => {
        try {
            await axios.delete(`/api/addonservices/delete/${id}`);
            message.success('Service deleted successfully');
            fetchAddOnServices();
        } catch (error) {
            message.error('Failed to delete service');
        }
    };

    const handleFormSubmit = async (values) => {
        const { dateApplied, ...rest } = values;

        // Ensure dateApplied is an array with two valid dates
        if (dateApplied && dateApplied.length === 2) {
            const formData = new FormData();

            // Append the form values
            formData.append('serviceName', rest.serviceName);
            formData.append('kindOfService', rest.kindOfService);
            formData.append('chargeType', rest.chargeType);
            formData.append('servicePrice', rest.servicePrice);
            formData.append('serviceDescription', rest.serviceDescription);

            // Format the dates and append them to formData
            formData.append('dateApplied[startDate]', dateApplied[0].toISOString()); // Start date
            formData.append('dateApplied[endDate]', dateApplied[1].toISOString());   // End date

            // Append the photo if it exists
            if (fileList.length > 0) {
                formData.append('photo', fileList[0].originFileObj); // Append the file
            }

            try {
                if (editingService) {
                    // Update existing service
                    await axios.put(`/api/addonservices/update/${editingService._id}`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    });
                    message.success('Service updated successfully');
                } else {
                    // Create new service
                    await axios.post('/api/addonservices/create', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    });
                    message.success('Service created successfully');
                }
                fetchAddOnServices();
                handleCancel();
            } catch (error) {
                message.error('Failed to save service');
            }
        } else {
            message.error('Please select a valid date range.');
        }
    };

    // Handle file upload
    const handleUploadChange = ({ fileList }) => {
        setFileList(fileList); // Set file list for form submission
    };

    const columns = [
        { title: 'Service Name', dataIndex: 'serviceName', key: 'serviceName' },
        { title: 'Kind of Service', dataIndex: 'kindOfService', key: 'kindOfService' },
        { title: 'Charge Type', dataIndex: 'chargeType', key: 'chargeType' },
        { title: 'Service Price', dataIndex: 'servicePrice', key: 'servicePrice' },
        { title: 'Service Description', dataIndex: 'serviceDescription', key: 'serviceDescription' },
        {
            title: 'Date Applied', dataIndex: 'dateApplied', key: 'dateApplied', render: (_, record) => (
                <span>{moment(record.dateApplied.startDate).format('DD-MM-YYYY')} - {moment(record.dateApplied.endDate).format('DD-MM-YYYY')}</span>
            )
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (_, record) => (
                <>
                    <Button type="primary" style={{ marginRight: 8 }} onClick={() => openModal(record)}>Edit</Button>
                    <Button type="link" danger onClick={() => handleDelete(record._id)}>Delete</Button>
                </>
            ),
        },
    ];

    return (
        <div className="container">
            <h1>Manage Add-On Services</h1>
            <Button type="primary" icon={<PlusOutlined />} onClick={() => openModal()}>Create New Service</Button>
            <Table
                dataSource={addOnServices}
                columns={columns}
                rowKey="_id"
                loading={loading}
                className="mt-4"
                pagination={{ pageSize: 10 }}
                scroll={{ x: 1000 }}
            />

            <Modal
                title={editingService ? 'Edit Service' : 'Create Service'}
                visible={isModalVisible}
                onCancel={handleCancel}
                footer={null}
            >
                <Form form={form} layout="vertical" onFinish={handleFormSubmit}>
                    <Form.Item name="serviceName" label="Service Name" rules={[{ required: true }]}>
                        <Input placeholder="Enter service name" />
                    </Form.Item>

                    <Form.Item name="kindOfService" label="Kind of Service" rules={[{ required: true }]}>
                        <Select placeholder="Select kind of service">
                            <Option value="Transportation">Transportation</Option>
                            <Option value="Wellness">Wellness</Option>
                            <Option value="F&B">F&B</Option>
                            <Option value="Tour">Tour</Option>
                            <Option value="Decoration">Decoration</Option>
                            <Option value="Entertainment">Entertainment</Option>
                            <Option value="Kid">Kid</Option>
                        </Select>
                    </Form.Item>

                    <Form.Item name="chargeType" label="Charge Type" rules={[{ required: true }]}>
                        <Select placeholder="Select charge type">
                            <Option value="Per Booking">Per Booking</Option>
                            <Option value="Per Pax">Per Pax</Option>
                            <Option value="Per Stay">Per Stay</Option>
                            <Option value="Per Night">Per Night</Option>
                        </Select>
                    </Form.Item>

                    <Form.Item name="servicePrice" label="Service Price" rules={[{ required: true }]}>
                        <Input type="number" placeholder="Enter service price" />
                    </Form.Item>

                    <Form.Item name="serviceDescription" label="Service Description" rules={[{ required: true }]}>
                        <Input.TextArea rows={4} placeholder="Enter service description" />
                    </Form.Item>

                    <Form.Item name="dateApplied" label="Date Applied" rules={[{ required: true }]}>
                        <RangePicker format="DD-MM-YYYY" />
                    </Form.Item>

                    <Form.Item name="photo" label="Upload Photo">
                        <Upload
                            listType="picture"
                            beforeUpload={() => false} // Disable auto upload
                            onChange={handleUploadChange} // Handle file selection
                            fileList={fileList}
                        >
                            <Button icon={<UploadOutlined />}>Click to Upload</Button>
                        </Upload>
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" block>
                            {editingService ? 'Update Service' : 'Create Service'}
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default AddOnServiceScreen;
