import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Form, Input, Button, Select, Table, Modal, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import 'antd/dist/reset.css';

const { Option } = Select;

const RatePlanManagementScreen = () => {
    const [ratePlans, setRatePlans] = useState([]);
    const [rooms, setRooms] = useState([]);
    const [cancellationPolicies, setCancellationPolicies] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [editingRatePlan, setEditingRatePlan] = useState(null);

    const [form] = Form.useForm();

    useEffect(() => {
        fetchRatePlans();
        fetchRoomsAndPolicies();
    }, []);

    const fetchRatePlans = async () => {
        setLoading(true);
        try {
            const response = await axios.get('/api/rateplans/getallrateplans');
            setRatePlans(Array.isArray(response.data) ? response.data : []);
        } catch (error) {
            message.error('Failed to fetch rate plans.');
            setRatePlans([]);
        } finally {
            setLoading(false);
        }
    };

    const fetchRoomsAndPolicies = async () => {
        try {
            const roomsResponse = await axios.get('/api/rooms/getallrooms');
            setRooms(Array.isArray(roomsResponse.data) ? roomsResponse.data : []);

            const policiesResponse = await axios.get('/api/policies/getcancellationpolicies');
            if (Array.isArray(policiesResponse.data)) {
                setCancellationPolicies(policiesResponse.data);
            } else {
                console.error('Unexpected response format for cancellation policies');
            }
        } catch (error) {
            message.error('Failed to fetch rooms or cancellation policies.');
        }
    };

    const handleCreate = () => {
        setEditingRatePlan(null);
        form.resetFields();
        setIsModalVisible(true);
    };

    const handleEdit = (ratePlan) => {
        setEditingRatePlan(ratePlan);
        const prefilledData = {
            ...ratePlan,
            cancellationPolicy: JSON.stringify(ratePlan.cancellationPolicy),
            extraBenefits: ratePlan.extraBenefits || [],
        };
        form.setFieldsValue(prefilledData);
        setIsModalVisible(true);
    };

    const handleDelete = async (ratePlanId) => {
        try {
            await axios.delete(`/api/rateplans/delete/${ratePlanId}`);
            message.success('Rate plan deleted successfully.');
            fetchRatePlans();
        } catch (error) {
            message.error('Failed to delete rate plan.');
        }
    };

    const handleFormSubmit = async () => {
        try {
            const values = await form.validateFields();
            const cancellationPolicy = JSON.parse(values.cancellationPolicy);

            const ratePlanData = {
                rateplanname: values.rateplanname,
                room: values.room,
                rateplanpricing: values.room.map(roomId => {
                    const existingPricing = editingRatePlan?.rateplanpricing.find(rp => rp.roomId === roomId) || {};
                    return {
                        roomId,
                        manualRate: existingPricing.manualRate || {},
                        deriveFromRentperdayByDate: existingPricing.deriveFromRentperdayByDate || false,
                    };
                }),
                cancellationPolicy,
                mealPlan: values.mealPlan,
                discount: values.discount,
                restrictions: values.restrictions,
                extraBenefits: values.extraBenefits || [],
                ratePlanDescription: values.ratePlanDescription || '',
                ratePlanSetup: values.ratePlanSetup,
            };

            if (editingRatePlan) {
                await axios.put(`/api/rateplans/update/${editingRatePlan._id}`, ratePlanData);
                message.success('Rate plan updated successfully.');
            } else {
                await axios.post('/api/rateplans/create', ratePlanData);
                message.success('Rate plan created successfully.');
            }

            setIsModalVisible(false);
            form.resetFields();
            fetchRatePlans();
        } catch (error) {
            message.error('Failed to save rate plan.');
        }
    };

    const columns = [
        {
            title: 'Rate Plan',
            dataIndex: 'rateplanname',
            key: 'rateplanname',
        },
        {
            title: 'Selected Room(s)',
            dataIndex: 'room',
            key: 'room',
            render: (roomIds) => {
                const roomNames = roomIds
                    .map(roomId => {
                        const room = rooms.find(r => r._id === roomId);
                        return room ? room.name : '';
                    })
                    .filter(Boolean)
                    .join(', ');
                return roomNames || 'No rooms selected';
            },
        },
        {
            title: 'Cancellation Policy',
            dataIndex: 'cancellationPolicy',
            key: 'cancellationPolicy',
            render: (cancellationPolicy) => {
                if (!cancellationPolicy) return 'No policy';
                const { chargeType, chargePercentage, daysBeforeCheckin } = cancellationPolicy;
                return `${chargeType} - ${chargePercentage}% charge if canceled ${daysBeforeCheckin} days before check-in.`;
            },
        },
        {
            title: 'Meal Plan',
            dataIndex: 'mealPlan',
            key: 'mealPlan',
        },
        {
            title: 'Discount (%)',
            dataIndex: 'discount',
            key: 'discount',
        },
        {
            title: 'Rate Plan Setup',
            dataIndex: 'ratePlanSetup',
            key: 'ratePlanSetup',
        },
        {
            title: 'Description',
            dataIndex: 'ratePlanDescription',
            key: 'ratePlanDescription',
            render: (description) => description || 'No description provided',
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (text, record) => (
                <>
                    <Button onClick={() => handleEdit(record)} type="primary" style={{ marginRight: 8 }}>
                        Edit
                    </Button>
                    <Button onClick={() => handleDelete(record._id)} type="link" >
                        Delete
                    </Button>
                </>
            ),
        },
    ];

    return (
        <div className='container'>
            <h1>Rate Plan Management</h1>
            <Button type="primary" icon={<PlusOutlined />} onClick={handleCreate} style={{ marginBottom: 16 }}>
                Create Rate Plan
            </Button>
            <Table
                dataSource={ratePlans}
                columns={columns}
                rowKey="_id"
                loading={loading}
                pagination={{ pageSize: 10 }}
                scroll={{ x: 1000 }} // Enables horizontal scrolling for smaller screens
            />

            <Modal
                title={editingRatePlan ? 'Edit Rate Plan' : 'Create Rate Plan'}
                visible={isModalVisible}
                onOk={handleFormSubmit}
                onCancel={() => setIsModalVisible(false)}
                width={800}
            >
                <Form form={form} layout="vertical">
                    <Form.Item
                        name="rateplanname"
                        label="Rate Plan Name"
                        rules={[{ required: true, message: 'Please enter rate plan name' }]}
                    >
                        <Input placeholder="Enter Rate Plan Name" />
                    </Form.Item>

                    <Form.Item
                        name="room"
                        label="Select Room(s)"
                        rules={[{ required: true, message: 'Please select room(s)' }]}
                    >
                        <Select
                            mode="multiple"
                            placeholder="Select Room(s)"
                            onChange={(value) => {
                                const currentPricing = form.getFieldValue('rateplanpricing') || [];
                                const updatedPricing = value.map(roomId => {
                                    const existing = currentPricing.find(rp => rp.roomId === roomId);
                                    return existing ? existing : { roomId, deriveFromRentperdayByDate: false, manualRate: {} };
                                });
                                form.setFieldsValue({ rateplanpricing: updatedPricing });
                            }}
                        >
                            {Array.isArray(rooms) && rooms.map((room) => (
                                <Option key={room._id} value={room._id}>
                                    {room.name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        name="cancellationPolicy"
                        label="Cancellation Policy"
                        rules={[{ required: true, message: 'Please select a cancellation policy' }]}
                    >
                        <Select placeholder="Select Cancellation Policy">
                            {Array.isArray(cancellationPolicies) && cancellationPolicies.length > 0 ? (
                                cancellationPolicies.map((policy, index) => (
                                    <Option key={index} value={JSON.stringify(policy)}>
                                        {policy.chargeType} - {policy.chargePercentage}% charge
                                        {policy.chargeType === 'Flexible' ? ` if canceled ${policy.daysBeforeCheckin} days before check-in` : ''}
                                    </Option>
                                ))
                            ) : (
                                <Option disabled>No cancellation policies available</Option>
                            )}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        name="mealPlan"
                        label="Meal Plan"
                        rules={[{ required: true, message: 'Please select a meal plan' }]}
                    >
                        <Select placeholder="Select Meal Plan">
                            <Option value="None">None</Option>
                            <Option value="Breakfast">Breakfast</Option>
                            <Option value="Half Board">Half Board</Option>
                            <Option value="Full Board">Full Board</Option>
                        </Select>
                    </Form.Item>

                    <Form.Item
                        name="discount"
                        label="Discount (%)"
                        rules={[{ required: true, message: 'Please enter discount percentage' }]}
                    >
                        <Input type="number" placeholder="Enter Discount" />
                    </Form.Item>

                    <Form.Item
                        name={['restrictions', 'minimumStay']}
                        label="Minimum Stay (nights)"
                        rules={[{ required: true, message: 'Please enter minimum stay' }]}
                    >
                        <Input type="number" placeholder="Enter Minimum Stay" />
                    </Form.Item>

                    <Form.Item
                        name={['restrictions', 'maximumStay']}
                        label="Maximum Stay (nights)"
                    >
                        <Input type="number" placeholder="Enter Maximum Stay" />
                    </Form.Item>

                    <Form.Item
                        name="ratePlanSetup"
                        label="Rate Plan Setup"
                        rules={[{ required: true, message: 'Please select rate plan setup' }]}
                    >
                        <Select placeholder="Select Rate Plan Setup">
                            <Option value="Manual">Manual</Option>
                            <Option value="Derived">Derived</Option>
                        </Select>
                    </Form.Item>

                    <Form.Item
                        name="ratePlanDescription"
                        label="Rate Plan Description"
                    >
                        <Input.TextArea placeholder="Enter Rate Plan Description" rows={3} />
                    </Form.Item>

                    <Form.Item
                        name="extraBenefits"
                        label="Extra Benefits"
                    >
                        <Select mode="tags" placeholder="Add Extra Benefits" />
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" onClick={handleFormSubmit} block>
                            {editingRatePlan ? 'Update Rate Plan' : 'Create Rate Plan'}
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default RatePlanManagementScreen;
