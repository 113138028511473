import React, { useContext, useEffect } from 'react';
import axios from 'axios';
import { BookingContext } from '../components/BookingContext'; // Context for managing booking details
import { CheckCircleOutlined } from '@ant-design/icons'; // Using Ant Design icons
import { message } from 'antd'; // Importing message for notifications

function PaymentMethodScreen() {
    const { setPaymentMethod, paymentMethod } = useContext(BookingContext); // Access both method and setter

    // Fetch payment method from the backend
    useEffect(() => {
        const fetchPaymentMethod = async () => {
            try {
                const response = await axios.get('/api/paymentMethod/getPaymentMethod');
                const method = response.data?.method || 'creditCard'; // Default to 'creditCard' if not set
                setPaymentMethod(method); // Set payment method from the backend
            } catch (error) {
                console.error('Error fetching payment method:', error);
                message.error('Error fetching payment method');
            }
        };

        fetchPaymentMethod();
    }, [setPaymentMethod]);

    const handlePaymentSelection = async (method) => {
        try {
            await axios.post('/api/paymentMethod/updatePaymentMethod', { method });
            console.log(`Payment method updated to: ${method}`);
            setPaymentMethod(method); // Update the global payment method in context
            message.success(`Payment method updated to: ${method}`);
        } catch (error) {
            console.error('Error updating payment method:', error);
            message.error('Error updating payment method');
        }
    };

    return (
        <div className="payment-method-container">
            <h2>Select Payment Method</h2>
            <p>Current Payment Method: {paymentMethod}</p> {/* Display current method */}
            <div className="payment-options">
                {['creditCard', 'Pay At Hotel', 'braintree', 'paypal', 'vnpay', 'onepay'].map((method) => (
                    <div
                        key={method}
                        className={`payment-option ${paymentMethod === method ? 'selected' : ''}`}
                        onClick={() => handlePaymentSelection(method)}
                    >
                        <span>{method.charAt(0).toUpperCase() + method.slice(1)}</span>
                        {paymentMethod === method && <CheckCircleOutlined style={{ color: 'green' }} />}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default PaymentMethodScreen;
