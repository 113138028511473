
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Table, Button, Modal, Form, Input, Select, DatePicker, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import moment from 'moment';

const { Option } = Select;
const { RangePicker } = DatePicker;

const PromotionManagementScreen = () => {
    const [promotions, setPromotions] = useState([]);
    const [rooms, setRooms] = useState([]);
    const [ratePlans, setRatePlans] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [editingPromotion, setEditingPromotion] = useState(null);
    const [promotionType, setPromotionType] = useState(null); // Track promotion type

    const [form] = Form.useForm();

    useEffect(() => {
        fetchPromotions();
        fetchRooms();
        fetchRatePlans();
    }, []);

    const fetchPromotions = async () => {
        setLoading(true);
        try {
            const response = await axios.get('/api/promotions/getallpromotions');
            setPromotions(Array.isArray(response.data) ? response.data : []); // Ensure it's an array
        } catch (error) {
            message.error('Failed to fetch promotions');
            setPromotions([]); // Set an empty array in case of error
        } finally {
            setLoading(false);
        }
    };

    const fetchRooms = async () => {
        try {
            const response = await axios.get('/api/rooms/getallrooms');
            setRooms(Array.isArray(response.data) ? response.data : []);
        } catch (error) {
            message.error('Failed to fetch rooms');
        }
    };

    const fetchRatePlans = async () => {
        try {
            const response = await axios.get('/api/rateplans/getallrateplans');
            setRatePlans(Array.isArray(response.data) ? response.data : []);
        } catch (error) {
            message.error('Failed to fetch rate plans');
        }
    };

    const handleCreate = () => {
        setEditingPromotion(null);
        setPromotionType(null); // Reset promotion type
        setIsModalVisible(true);
    };

    const handleEdit = (promotion) => {
        setEditingPromotion(promotion);
        setPromotionType(promotion.promotionType); // Set promotion type for editing
        setIsModalVisible(true);
        form.setFieldsValue({
            ...promotion,
            applicableStayDates: [moment(promotion.applicableStayDates.startDate), moment(promotion.applicableStayDates.endDate)],
            applicableBookDates: [moment(promotion.applicableBookDates.startDate), moment(promotion.applicableBookDates.endDate)],
        });
    };

    const handleDelete = async (promotionId) => {
        try {
            await axios.delete(`/api/promotions/delete/${promotionId}`);
            message.success('Promotion deleted successfully');
            fetchPromotions();
        } catch (error) {
            message.error('Failed to delete promotion');
        }
    };

    const handleModalOk = async () => {
        try {
            const values = await form.validateFields();
            const { applicableStayDates, applicableBookDates, promoCode, ...rest } = values;
            const payload = {
                ...rest,
                promoCode: promotionType === 'Promo Code' ? promoCode : undefined, // Only send if Promo Code type
                applicableStayDates: {
                    startDate: applicableStayDates[0].toISOString(),
                    endDate: applicableStayDates[1].toISOString(),
                },
                applicableBookDates: {
                    startDate: applicableBookDates[0].toISOString(),
                    endDate: applicableBookDates[1].toISOString(),
                },
            };

            if (editingPromotion) {
                await axios.put(`/api/promotions/update/${editingPromotion._id}`, payload);
                message.success('Promotion updated successfully');
            } else {
                await axios.post('/api/promotions/create', payload);
                message.success('Promotion created successfully');
            }

            setIsModalVisible(false);
            form.resetFields();
            fetchPromotions();
        } catch (error) {
            message.error('Failed to save promotion');
        }
    };

    const handleModalCancel = () => {
        setIsModalVisible(false);
        form.resetFields();
    };

    const handlePromotionTypeChange = (value) => {
        setPromotionType(value);
    };

    // Function to render the condition based on promotionType
    const renderCondition = (record) => {
        if (record.promotionType === 'Last Minute') {
            return `Days before Check-in: ${record.condition.lastMinuteDays}`;
        } else if (record.promotionType === 'Early Bird') {
            return `Days in Advance: ${record.condition.earlyBirdDays}`;
        }
        return 'N/A'; // For promotions without specific conditions like Basic Deal, Seasonal, etc.
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Type',
            dataIndex: 'promotionType',
            key: 'promotionType',
        },
        {
            title: 'Discount (%)',
            dataIndex: 'discountPercentage',
            key: 'discountPercentage',
        },
        {
            title: 'Condition',
            key: 'condition',
            render: (text, record) => renderCondition(record), // Render condition dynamically based on promotion type
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (text, record) => (
                <>
                    <Button onClick={() => handleEdit(record)} type="primary" style={{ marginRight: 8 }}>
                        Edit
                    </Button>
                    <Button onClick={() => handleDelete(record._id)} type="link">
                        Delete
                    </Button>
                </>
            ),
        },
    ];

    return (
        <div className='container'>
            <h1>Promotion Management</h1>
            <Button type="primary" icon={<PlusOutlined />} onClick={handleCreate} style={{ marginBottom: 16 }}>
                Create Promotion
            </Button>
            <Table dataSource={promotions}
                columns={columns}
                rowKey="_id"
                loading={loading}
                pagination={{ pageSize: 10 }}
                scroll={{ x: 1000 }}
            />

            <Modal
                title={editingPromotion ? 'Edit Promotion' : 'Create Promotion'}
                visible={isModalVisible}
                onOk={handleModalOk}
                onCancel={handleModalCancel}
            >
                <Form form={form} layout="vertical">
                    <Form.Item name="name" label="Promotion Name" rules={[{ required: true, message: 'Please enter promotion name' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="promotionType" label="Promotion Type" rules={[{ required: true, message: 'Please select promotion type' }]}>
                        <Select onChange={handlePromotionTypeChange}>
                            <Option value="Basic Deal">Basic Deal</Option>
                            <Option value="Last Minute">Last Minute</Option>
                            <Option value="Early Bird">Early Bird</Option>
                            <Option value="Seasonal">Seasonal</Option>
                            <Option value="Special Offer">Special Offer</Option>
                            <Option value="Promo Code">Promo Code</Option>
                        </Select>
                    </Form.Item>

                    {promotionType === 'Promo Code' && (
                        <Form.Item
                            name="promoCode"
                            label="Promo Code"
                            rules={[{ required: true, message: 'Please enter a unique promo code' }]}
                        >
                            <Input placeholder="Enter a unique promo code" />
                        </Form.Item>
                    )}

                    {/* Dynamic condition fields based on promotion type */}
                    {promotionType === 'Last Minute' && (
                        <Form.Item name="lastMinuteDays" label="Days before Check-in" rules={[{ required: true, message: 'Please specify how many days before check-in' }]}>
                            <Input type="number" />
                        </Form.Item>
                    )}

                    {promotionType === 'Early Bird' && (
                        <Form.Item name="earlyBirdDays" label="Days in Advance" rules={[{ required: true, message: 'Please specify how far in advance customers need to book' }]}>
                            <Input type="number" />
                        </Form.Item>
                    )}

                    <Form.Item name="discountPercentage" label="Discount Percentage" rules={[{ required: true, message: 'Please enter discount percentage' }]}>
                        <Input type="number" />
                    </Form.Item>
                    <Form.Item name="applicableStayDates" label="Applicable Stay Dates" rules={[{ required: true, message: 'Please select stay dates' }]}>
                        <RangePicker />
                    </Form.Item>
                    <Form.Item name="applicableBookDates" label="Applicable Book Dates" rules={[{ required: true, message: 'Please select booking dates' }]}>
                        <RangePicker />
                    </Form.Item>
                    <Form.Item name="applicableRooms" label="Applicable Rooms" rules={[{ required: false }]}>
                        <Select mode="multiple" placeholder="Select applicable rooms">
                            {rooms.map(room => (
                                <Option key={room._id} value={room._id}>
                                    {room.name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item name="applicableRatePlans" label="Applicable Rate Plans" rules={[{ required: false }]}>
                        <Select mode="multiple" placeholder="Select applicable rate plans">
                            {ratePlans.map(ratePlan => (
                                <Option key={ratePlan._id} value={ratePlan._id}>
                                    {ratePlan.rateplanname}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default PromotionManagementScreen;
