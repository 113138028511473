import React, { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Switch, Upload, Button, Select } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

const { Option } = Select;

function AddRoomScreen() {
    const [name, setName] = useState('');
    const [type, setType] = useState('');
    const [rentperday, setRentPerDay] = useState('');
    const [maxcount, setMaxCount] = useState('');
    const [description, setDescription] = useState('');
    const [phonenumber, setPhoneNumber] = useState('');
    const [maxadult, setMaxAdult] = useState('');
    const [maxchild, setMaxChildren] = useState('');
    const [extrabed, setExtraBed] = useState(false);
    const [roomview, setRoomView] = useState('');
    const [roomsize, setRoomSize] = useState('');
    const [smokingpolicy, setSmokingPolicy] = useState(false);
    const [facilities, setFacilities] = useState([]);
    const [bedding, setBedding] = useState('');
    const [manualBeds, setManualBeds] = useState('');
    const [imageFiles, setImageFiles] = useState([]);
    const [loading, setLoading] = useState(false); // For loading state
    const [errors, setErrors] = useState({});

    const facilityOptions = [
        'Wi-Fi', 'Air Conditioning', 'Television', 'In-room Safe', 'Minibar', 'Coffee/Tea Maker', 'Iron/Ironing Board',
        'Hairdryer', 'Desk/Workspace', 'Room Service', 'Laundry Service', 'Telephone', 'Balcony/Terrace',
        'Private Bathroom', 'Shower', 'Bathtub', 'Bathrobe', 'Slippers', 'Complimentary Toiletries',
        'Soundproof Rooms', 'Heating', 'Wardrobe/Closet', 'Sofa', 'Kitchenette', 'Microwave', 'Refrigerator',
        'Electric Kettle', 'Blackout Curtains', 'Safe', 'Fitness Center Access'
    ];

    const roomViewOptions = [
        'Ocean View', 'Mountain View', 'Garden View', 'City View', 'River View', 'Lake View', 'No View', 'Forest View', 'Bay View', 'Park View', 'Partial Sea View', 'Partial City View', 'Partial River View', 'Partial Garden View'
    ];

    const roomTypeOptions = [
        'Single', 'Double', 'Triple', 'Twin', 'Double/Twin', 'Quad', 'Family', 'Studio', 'Apartment', 'Dorm',
        'Villa', 'Vacation Home', 'Bungalow', 'Tent'
    ];

    const beddingOptions = [
        'King Bed', 'Queen Bed', 'Single Bed', 'Sofa Bed', 'Bunk Bed',
        '1 King Bed and 1 Single Bed', '1 King Bed and 2 Single Beds',
        '1 Queen Bed and 1 Single Bed', '1 Queen Bed and 2 Single Beds',
        '2 Single Beds', '3 Single Beds', '4 Single Beds',
        'King Bed and Sofa Bed', 'Queen Bed and Sofa Bed', 'Single Bed and Sofa Bed',
        '2 King Beds', '2 Queen Beds', 'Input No of Bed Manually'
    ];

    const handleFacilityChange = (selectedFacilities) => {
        setFacilities(selectedFacilities);
    };

    const handleImageChange = ({ fileList }) => {
        setImageFiles(fileList.map(file => file.originFileObj));
    };

    const validateFields = () => {
        const newErrors = {};
        if (!name) newErrors.name = 'Room Name is required.';
        if (!type) newErrors.type = 'Room Type is required.';
        if (!rentperday) newErrors.rentperday = 'Room Rate is required.';
        if (!maxcount) newErrors.maxcount = 'Number of Rooms is required.';
        if (!description) newErrors.description = 'Room Description is required.';
        if (!phonenumber) newErrors.phonenumber = 'Phone Number is required.';
        if (!maxadult) newErrors.maxadult = 'Maximum Adults is required.';
        if (!maxchild) newErrors.maxchild = 'Maximum Children is required.';
        if (!roomview) newErrors.roomview = 'Room View is required.';
        if (!roomsize) newErrors.roomsize = 'Room Size is required.';
        if (imageFiles.length === 0) newErrors.images = 'At least one image is required.';
        if (!bedding) newErrors.bedding = 'Bedding type is required.';
        if (bedding === 'Input No of Bed Manually' && !manualBeds) {
            newErrors.manualBeds = 'Number of Beds is required.';
        }
        if (facilities.length === 0) newErrors.facilities = 'At least one facility is required.';
        return newErrors;
    };

    async function addRoom() {

        const newErrors = validateFields();
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }

        setLoading(true); // Start loading
        const formData = new FormData();
        formData.append('name', name);
        formData.append('rentperday', rentperday);
        formData.append('maxcount', maxcount);
        formData.append('description', description);
        formData.append('phonenumber', phonenumber);
        formData.append('type', type);
        formData.append('maxadult', maxadult);
        formData.append('maxchild', maxchild);
        formData.append('extrabed', extrabed);
        formData.append('roomview', roomview);
        formData.append('roomsize', roomsize);
        formData.append('smokingpolicy', smokingpolicy);
        formData.append('facilities', facilities);
        formData.append('bedding', bedding === 'Input No of Bed Manually' ? manualBeds : bedding);

        if (imageFiles.length > 0) {
            for (let i = 0; i < imageFiles.length; i++) {
                formData.append('images', imageFiles[i]);
            }
        }

        try {
            await axios.post('/api/rooms/addroom', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            Swal.fire({
                title: 'Congratulations',
                text: 'Your Room Has Been Added Successfully',
                icon: 'success',
                timer: 2000,
            }).then(() => {
                window.location.href = '/admin/roominglist'; // Redirect to RoomingListScreen
            });
        } catch (error) {
            console.log(error);
            Swal.fire({
                title: 'Oops',
                text: 'Something went wrong!',
                icon: 'error',
                timer: 2000,
            })
        } finally {
            setLoading(false); // Stop loading
        }
    }

    const uploadButton = (
        <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    return (
        <div className='row ms-5 mb-5 me-5'>
            <h1>ADD YOUR NEW ROOM</h1>
            <div className='col-md-6'>
                <div className='mb-2'>
                    <label><strong>Room Name</strong></label>
                    <input
                        type='text'
                        className='form-control'
                        placeholder='Room Name'
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                    {errors.name && <span className="text-danger">{errors.name}</span>}
                </div>
                <div className='mb-2'>
                    <label><strong>Room Rate</strong></label>
                    <input
                        type="text"
                        className={`form-control ${errors.rentperday ? 'is-invalid' : ''}`}
                        placeholder="Room Rate"
                        value={rentperday}
                        onChange={(e) => setRentPerDay(e.target.value)}
                        onKeyDown={(e) => {
                            if (!/^\d*$/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Delete') {
                                e.preventDefault();
                                setErrors((prev) => ({ ...prev, rentperday: 'Only numbers are allowed.' }));
                            } else {
                                setErrors((prev) => ({ ...prev, rentperday: '' })); // Clear error
                            }
                        }}
                    />
                    {errors.rentperday && <span className="text-danger">{errors.rentperday}</span>}
                </div>
                <div className='mb-2'>
                    <label><strong>Number of Rooms</strong></label>
                    <input
                        type="text"
                        className={`form-control ${errors.maxcount ? 'is-invalid' : ''}`}
                        placeholder="Number of Rooms"
                        value={maxcount}
                        onChange={(e) => setMaxCount(e.target.value)}
                        onKeyDown={(e) => {
                            if (!/^\d*$/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Delete') {
                                e.preventDefault();
                                setErrors((prev) => ({ ...prev, maxcount: 'Only numbers are allowed.' }));
                            } else {
                                setErrors((prev) => ({ ...prev, maxcount: '' })); // Clear error
                            }
                        }}
                    />
                    {errors.maxcount && <span className="text-danger">{errors.maxcount}</span>}
                </div>
                <div className='mb-2'>
                    <label><strong>Description</strong></label>
                    <textarea
                        className='form-control'
                        placeholder='Description'
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        rows={5} // Set height for bigger input
                    />
                    {errors.description && <span className="text-danger">{errors.description}</span>}
                </div>
                <div className='mb-2'>
                    <label><strong>Phone Number</strong></label>
                    <input
                        type="text"
                        className={`form-control ${errors.phonenumber ? 'is-invalid' : ''}`}
                        placeholder="Phone Number"
                        value={phonenumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        onKeyDown={(e) => {
                            if (!/^\d*$/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Delete') {
                                e.preventDefault();
                                setErrors((prev) => ({ ...prev, phonenumber: 'Only numbers are allowed.' }));
                            } else {
                                setErrors((prev) => ({ ...prev, phonenumber: '' })); // Clear error
                            }
                        }}
                    />
                    {errors.phonenumber && <span className="text-danger">{errors.phonenumber}</span>}
                </div>
                <div className='mb-2'>
                    <label><strong>Maximum Adults</strong></label>
                    <input
                        type='number'
                        className='form-control'
                        placeholder='Maximum Adults'
                        value={maxadult}
                        onChange={(e) => setMaxAdult(e.target.value)}
                    />
                    {errors.maxadult && <span className="text-danger">{errors.maxadult}</span>}
                </div>
                <div className='mb-2'>
                    <label><strong>Maximum Children</strong></label>
                    <input
                        type='number'
                        className='form-control'
                        placeholder='Maximum Children'
                        value={maxchild}
                        onChange={(e) => setMaxChildren(e.target.value)}
                    />
                    {errors.maxchild && <span className="text-danger">{errors.maxchild}</span>}
                </div>
            </div>

            <div className='col-md-6'>
                <div className='mb-2'>
                    <label><strong>Room Type</strong></label>
                    <select
                        className='form-control'
                        placeholder='Select Room Type'
                        value={type}
                        onChange={(e) => setType(e.target.value)}
                    >
                        <option value="">Select Room Type</option>
                        {roomTypeOptions.map((type) => (
                            <option key={type} value={type}>{type}</option>
                        ))}
                    </select>
                    {errors.type && <span className="text-danger">{errors.type}</span>}
                </div>
                <div className='mb-2'>
                    <label><strong>Room View</strong></label>
                    <select
                        className='form-control'
                        placeholder='Select Room View'
                        value={roomview}
                        onChange={(e) => setRoomView(e.target.value)}
                    >
                        <option value="">Select Room View</option>
                        {roomViewOptions.map((view) => (
                            <option key={view} value={view}>{view}</option>
                        ))}
                    </select>
                    {errors.roomview && <span className="text-danger">{errors.roomview}</span>}
                </div>
                <div className='mb-2'>
                    <label><strong>Room Size (sq meters)</strong></label>
                    <input
                        type="text"
                        className={`form-control ${errors.roomsize ? 'is-invalid' : ''}`}
                        placeholder="Room Size"
                        value={roomsize}
                        onChange={(e) => setRoomSize(e.target.value)}
                        onKeyDown={(e) => {
                            if (!/^\d*$/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Delete') {
                                e.preventDefault();
                                setErrors((prev) => ({ ...prev, roomsize: 'Only numbers are allowed.' }));
                            } else {
                                setErrors((prev) => ({ ...prev, roomsize: '' })); // Clear error
                            }
                        }}
                    />
                    {errors.roomsize && <span className="text-danger">{errors.roomsize}</span>}
                </div>
                <div className='mb-2'>
                    <label><strong>Upload Images (up to 5)</strong></label>
                    <Upload
                        listType="picture-card"
                        multiple
                        accept="image/*"
                        beforeUpload={() => false}
                        onChange={handleImageChange}
                        showUploadList={{
                            showPreviewIcon: true,
                            showRemoveIcon: true,
                        }}
                        maxCount={5}
                    >
                        {imageFiles.length >= 5 ? null : uploadButton}
                    </Upload>
                    {errors.imageFiles && <span className="text-danger">{errors.imageFiles}</span>}
                </div>
                <div className='mb-2'>
                    <label><strong>Bedding</strong></label>
                    <select
                        className='form-control'
                        placeholder='Select Bed Type'
                        value={bedding}
                        onChange={(e) => setBedding(e.target.value)}
                    >
                        <option value="">Select Bed Type</option>
                        {beddingOptions.map((option) => (
                            <option key={option} value={option}>
                                {option}
                            </option>
                        ))}
                    </select>
                    {errors.bedding && <span className="text-danger">{errors.bedding}</span>}
                </div>

                {bedding === 'Input No of Bed Manually' && (
                    <div className='mb-2'>
                        <label><strong>Number of Beds</strong></label>
                        <input
                            type='number'
                            className='form-control'
                            value={manualBeds}
                            onChange={(e) => setManualBeds(e.target.value)}
                        />
                        {errors.manualBeds && <span className="text-danger">{errors.manualBeds}</span>}
                    </div>
                )}

                <div className='mb-3'>
                    <label><strong>Facilities</strong></label>
                    <Select
                        mode="multiple"
                        allowClear
                        style={{ width: '100%' }}
                        placeholder="Select Facilities"
                        value={facilities}
                        onChange={handleFacilityChange}
                    >
                        {facilityOptions.map((facility) => (
                            <Option key={facility} value={facility}>
                                {facility}
                            </Option>
                        ))}
                    </Select>
                    {errors.facilities && <span className="text-danger">{errors.facilities}</span>}
                </div>

                <div className='mb-3'>
                    <label><strong>Extra Bed</strong></label>
                    <div className="d-flex align-items-center">
                        <Switch
                            checked={extrabed}
                            onChange={(checked) => setExtraBed(checked)}
                        />
                        <span className="ms-2">{extrabed ? 'Yes' : 'No'}</span>
                    </div>
                </div>

                <div className='mb-2'>
                    <label><strong>Smoking Policy</strong></label>
                    <div className="d-flex align-items-center">
                        <Switch
                            checked={smokingpolicy}
                            onChange={(checked) => setSmokingPolicy(checked)}
                        />
                        <span className="ms-2">{smokingpolicy ? 'Smoking Allowed' : 'No Smoking'}</span>
                    </div>
                </div>

                <div className='d-flex justify-content-end'>
                    <Button
                        type="primary"
                        onClick={addRoom}
                        loading={loading} // Show loading spinner while saving
                    >
                        {loading ? 'Saving...' : 'Add Room'}
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default AddRoomScreen;
