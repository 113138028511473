//back to this
import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Room from '../components/Room';
import Error from '../components/Error';
import { Button, Tag, Divider, message, Select } from 'antd';
import { DeleteOutlined, CloseOutlined, DownOutlined, UpOutlined, ShoppingCartOutlined } from '@ant-design/icons'; // Icons for close and toggle
import Swal from 'sweetalert2';
import Draggable from 'react-draggable'; // Import Draggable library
import 'antd/dist/reset.css';
import moment from 'moment';
import { BookingContext } from '../components/BookingContext';
import './Homescreen.css'

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import { CurrencyContext } from '../components/CurrencyContext';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

function Homescreen() {
    const [rooms, setRooms] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [ratePlans, setRatePlans] = useState({});
    const [fromdate, setFromdate] = useState();
    const [todate, setTodate] = useState();
    const [duplicaterooms, setDuplicaterooms] = useState([]);
    const [adultsFilter, setAdultsFilter] = useState('');
    const [childrenFilter, setChildrenFilter] = useState('');
    const [promoCode, setPromoCode] = useState('');
    const [promoDiscount, setPromoDiscount] = useState(null);
    const [extraBedPrice, setExtraBedPrice] = useState(0);
    const [promotions, setPromotions] = useState([]);
    const [addonservices, setAddOnServices] = useState([]);
    const [isMinimized, setIsMinimized] = useState(false);
    const [expandedRooms, setExpandedRooms] = useState({}); // Track which rooms are expanded
    const [showSidebar, setShowSidebar] = useState(true);
    const { bookingSidebar, setBookingSidebar } = useContext(BookingContext);
    const [isMobile, setIsMobile] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const [branding, setBranding] = useState('');
    const { t, i18n } = useTranslation();
    const { currency, setCurrency, convertAmount } = useContext(CurrencyContext);
    const [language, setLanguage] = useState(i18n.language);

    const navigate = useNavigate();

    const formatCurrency = (amount) => {
        const locales = {
            USD: 'en-US',
            VND: 'vi-VN',
            EUR: 'de-DE',
            JPY: 'ja-JP',
            CNY: 'zh-CN',
            KRW: 'ko-KR',
            THB: 'th-TH',
        };

        const currencySymbols = {
            USD: 'USD',
            VND: 'VND',
            EUR: 'EUR',
            JPY: 'JPY',
            CNY: 'CNY',
            KRW: 'KRW',
            THB: 'THB',
        };

        // Apply conversion rate based on the selected currency
        const convertedAmount = convertAmount(amount, currency);

        return new Intl.NumberFormat(locales[currency] || 'vi-VN', {
            style: 'currency',
            currency: currencySymbols[currency] || 'VND',
            maximumFractionDigits: 0,
        }).format(convertedAmount);
    };
    // Fetch Rooms, Rate Plans, and Add-On Services
    useEffect(() => {
        const fetchRoomsAndRatePlans = async () => {
            try {
                setLoading(true);
                const roomsResponse = await axios.get('/api/rooms/getallrooms');
                setRooms(roomsResponse.data);
                setDuplicaterooms(roomsResponse.data);

                const ratePlansResponse = await axios.get('/api/rateplans/getallrateplans');
                const ratePlansByRoom = {};
                ratePlansResponse.data.forEach(ratePlan => {
                    ratePlan.room.forEach(roomId => {
                        if (!ratePlansByRoom[roomId]) {
                            ratePlansByRoom[roomId] = [];
                        }
                        ratePlansByRoom[roomId].push(ratePlan);
                    });
                });
                setRatePlans(ratePlansByRoom);

                // Fetch promotions
                const promotionsResponse = await axios.get('/api/promotions/getallpromotions');
                setPromotions(promotionsResponse.data);

                setLoading(false);
            } catch (error) {
                setError(true);
                setLoading(false);
                console.log(error);
            }
        };

        fetchRoomsAndRatePlans();
    }, []);

    useEffect(() => {
        const fetchAddOnServices = async () => {
            try {
                const addonServicesResponse = await axios.get('/api/addonservices/getalladdonservices');
                setAddOnServices(addonServicesResponse.data);
            } catch (error) {
                console.error('Error fetching add-on services:', error);
            }
        };

        fetchAddOnServices();
    }, []);

    // Fetch Extra Bed Price separately
    useEffect(() => {
        const fetchExtraBedPrice = async () => {
            try {
                const extraBedPriceResponse = await axios.get('/api/extrabedprice/get');
                setExtraBedPrice(extraBedPriceResponse.data.price);
            } catch (error) {
                console.error('Error fetching extra bed price:', error);
            }
        };

        fetchExtraBedPrice();
    }, []);

    useEffect(() => {
        const fetchBranding = async () => {
            try {
                const response = await axios.get('/api/branding/getallbranding');
                setBranding(response.data || { roomNameColor: '#443d80' });
            } catch (error) {
                console.error("Error fetching branding settings:", error);
            }
        };

        fetchBranding();
    }, []);

    useEffect(() => {
        // Detect if the device is mobile
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handlePickAddOnService = (service) => {
        setBookingSidebar((prev) => [
            ...prev,
            {
                type: 'addonservice',
                serviceName: service.serviceName,
                servicePrice: service.servicePrice,
                chargeType: service.ChargeType,
                serviceDescription: service.serviceDescription,
                serviceId: service._id,
            },
        ]);
    };

    const handleRemoveAddOnService = (serviceId) => {
        setBookingSidebar(prev => prev.filter(plan => plan.serviceId !== serviceId));
    };

    function getAvailableRooms(room, excludeRatePlanId = null) {
        if (!room || !room.maxcount) {
            console.error("Room data is undefined or missing 'maxcount'.");
            return 0;
        }

        let startDate = moment(fromdate, 'DD-MM-YYYY');
        const endDate = moment(todate, 'DD-MM-YYYY').subtract(1, 'days');
        let minAvailableRooms = room.maxcount;

        while (startDate.isSameOrBefore(endDate)) {
            const dateStr = startDate.format('DD-MM-YYYY');
            let available = room.availability[dateStr] !== undefined ? room.availability[dateStr] : room.maxcount;

            if (isNaN(available)) {
                available = room.maxcount;
            }

            if (room.closeout && room.closeout[dateStr]) {
                return 0;
            }

            const totalSelectedRooms = bookingSidebar.reduce((acc, plan) => {
                const selectedRoom = rooms.find(r => r._id === plan.roomId);
                if (selectedRoom?._id === room._id && plan.ratePlanId !== excludeRatePlanId) {
                    return acc + plan.selectedRooms;
                }
                return acc;
            }, 0);

            available -= totalSelectedRooms;

            if (available < minAvailableRooms) {
                minAvailableRooms = available;
            }

            startDate = startDate.add(1, 'days');
        }

        return minAvailableRooms;
    }

    const handleDateChange = (date, type) => {
        const formattedDate = date ? date.format('DD-MM-YYYY') : null;

        if (type === 'start') {
            setFromdate(formattedDate);
            setStartDate(date);
        } else {
            setTodate(formattedDate);
            setEndDate(date);
        }

        // Call filterByDate directly with updated dates
        if (type === 'end' && startDate && date) {
            filterByDate([moment(startDate, 'DD-MM-YYYY'), date]);
        } else if (type === 'start' && endDate && date) {
            filterByDate([date, moment(endDate, 'DD-MM-YYYY')]);
        }
    };


    function filterByDate(dates) {
        if (dates && dates.length === 2) {
            const fromDateFormatted = dates[0].format('DD-MM-YYYY');
            const toDateFormatted = dates[1].format('DD-MM-YYYY');
            setFromdate(fromDateFormatted);
            setTodate(toDateFormatted);

            const today = moment();

            const temprooms = duplicaterooms.filter(room => {
                let availability = true;
                let closeoutExists = false;
                let maxMinStay = 0;
                let minMaxStay = Infinity;

                for (let d = moment(fromDateFormatted, 'DD-MM-YYYY'); d.isSameOrBefore(moment(toDateFormatted, 'DD-MM-YYYY')); d.add(1, 'days')) {
                    const dateStr = d.format('DD-MM-YYYY');

                    if (room.closeout && Object.keys(room.closeout).length > 0) {
                        let startDate = moment(fromDateFormatted, 'DD-MM-YYYY');
                        const endDate = moment(toDateFormatted, 'DD-MM-YYYY').subtract(1, 'days');

                        while (startDate.isSameOrBefore(endDate)) {
                            const dateStr = startDate.format('DD-MM-YYYY');

                            if (room.closeout[dateStr]) {
                                closeoutExists = true;
                                break;
                            }

                            startDate = startDate.add(1, 'days');
                        }
                    }

                    if (closeoutExists) {
                        availability = false;
                    }

                    if (room.currentbookings && room.currentbookings.length > 0) {
                        let bookedRoomsCount = 0;

                        for (const booking of room.currentbookings) {
                            const bookingFromDate = moment(booking.fromdate, 'DD-MM-YYYY');
                            const bookingToDate = moment(booking.todate, 'DD-MM-YYYY');

                            if (moment(dateStr, 'DD-MM-YYYY').isBetween(bookingFromDate, bookingToDate, null, '[]')) {
                                bookedRoomsCount += booking.roomCount || 1;
                            }
                        }

                        if (bookedRoomsCount >= room.maxcount) {
                            availability = false;
                            break;
                        }
                    }
                }

                for (let d = moment(fromDateFormatted, 'DD-MM-YYYY'); d.isBefore(moment(toDateFormatted, 'DD-MM-YYYY')); d.add(1, 'days')) {
                    const dateStr = d.format('DD-MM-YYYY');

                    if (room.minimumStay && room.minimumStay[dateStr]) {
                        maxMinStay = Math.max(maxMinStay, room.minimumStay[dateStr]);
                    }

                    if (room.maximumStay && room.maximumStay[dateStr]) {
                        minMaxStay = Math.min(minMaxStay, room.maximumStay[dateStr]);
                    }
                }

                const selectedRangeDays = moment(toDateFormatted, 'DD-MM-YYYY').diff(moment(fromDateFormatted, 'DD-MM-YYYY'), 'days');

                if (selectedRangeDays < maxMinStay) {
                    availability = false;
                }

                if (selectedRangeDays > minMaxStay) {
                    availability = false;
                }

                return availability;
            }).map(room => {
                const roomWithDiscountedRatePlans = { ...room };
                roomWithDiscountedRatePlans.ratePlans = (ratePlans[room._id] || []).map(ratePlan => {
                    let discountedRate = ratePlan.ratePlanRate;
                    const checkInDate = moment(fromDateFormatted, 'DD-MM-YYYY');

                    const lastMinutePromotion = promotions.find(
                        promo => promo.promotionType === 'Last Minute' &&
                            promo.applicableRooms.includes(room._id) &&
                            promo.applicableRatePlans.includes(ratePlan._id)
                    );

                    if (lastMinutePromotion) {
                        const lastMinuteDays = lastMinutePromotion.condition?.lastMinuteDays || 3;
                        const daysBeforeCheckIn = checkInDate.startOf('day').diff(today.startOf('day'), 'days') + 1;

                        if (daysBeforeCheckIn > 0 && daysBeforeCheckIn <= lastMinuteDays) {
                            const discountPercentage = lastMinutePromotion.discountPercentage || 0;
                            discountedRate = discountedRate * (1 - discountPercentage / 100);
                        }
                    }

                    const earlyBirdPromotion = promotions.find(
                        promo => promo.promotionType === 'Early Bird' &&
                            promo.applicableRooms.includes(room._id) &&
                            promo.applicableRatePlans.includes(ratePlan._id)
                    );

                    if (earlyBirdPromotion) {
                        const earlyBirdDays = earlyBirdPromotion.condition?.earlyBirdDays || 30;
                        const daysBeforeCheckIn = checkInDate.diff(today, 'days');

                        if (daysBeforeCheckIn >= earlyBirdDays) {
                            const discountPercentage = earlyBirdPromotion.discountPercentage || 0;
                            discountedRate = discountedRate * (1 - discountPercentage / 100);
                        }
                    }

                    const basicDealPromotion = promotions.find(
                        promo => promo.promotionType === 'Basic Deal' &&
                            promo.applicableRooms.includes(room._id) &&
                            promo.applicableRatePlans.includes(ratePlan._id)
                    );

                    if (basicDealPromotion) {
                        const { startDate: bookStartDate, endDate: bookEndDate } = basicDealPromotion.applicableBookDates;
                        const { startDate: stayStartDate, endDate: stayEndDate } = basicDealPromotion.applicableStayDates;

                        const isWithinBookingPeriod = today.isBetween(
                            moment(bookStartDate),
                            moment(bookEndDate),
                            null,
                            '[]'
                        );

                        const isWithinStayPeriod = checkInDate.isBetween(
                            moment(stayStartDate),
                            moment(stayEndDate),
                            null,
                            '[]'
                        );

                        if (isWithinBookingPeriod && isWithinStayPeriod) {
                            const discountPercentage = basicDealPromotion.discountPercentage || 0;
                            discountedRate = discountedRate * (1 - discountPercentage / 100);
                        }
                    }

                    discountedRate = calculateFinalRateWithPromo(discountedRate);

                    return {
                        ...ratePlan,
                        ratePlanRate: discountedRate,
                    };
                });

                return roomWithDiscountedRatePlans;
            });

            setRooms(temprooms);
        }
    }

    function filterByOccupancy() {
        const filteredRooms = duplicaterooms.filter(room => {
            const isMatchingOccupancy =
                room.maxadult >= adultsFilter &&
                room.maxchild >= childrenFilter;

            if (!isMatchingOccupancy) return false;

            let availability = true;
            let closeoutExists = false;
            let maxMinStay = 0;

            for (let d = moment(fromdate, 'DD-MM-YYYY'); d.isSameOrBefore(moment(todate, 'DD-MM-YYYY')); d.add(1, 'days')) {
                const dateStr = d.format('DD-MM-YYYY');

                if (room.closeout && Object.keys(room.closeout).length > 0) {
                    let startDate = moment(fromdate, 'DD-MM-YYYY');
                    const endDate = moment(todate, 'DD-MM-YYYY').subtract(1, 'days');

                    while (startDate.isSameOrBefore(endDate)) {
                        const dateStr = startDate.format('DD-MM-YYYY');

                        if (room.closeout[dateStr]) {
                            closeoutExists = true;
                            break;
                        }

                        startDate = startDate.add(1, 'days');
                    }
                }

                if (closeoutExists) {
                    availability = false;
                    break;
                }

                if (room.currentbookings && room.currentbookings.length > 0) {
                    let bookedRoomsCount = 0;

                    for (const booking of room.currentbookings) {
                        const bookingFromDate = moment(booking.fromdate, 'DD-MM-YYYY');
                        const bookingToDate = moment(booking.todate, 'DD-MM-YYYY');

                        if (moment(dateStr, 'DD-MM-YYYY').isBetween(bookingFromDate, bookingToDate, null, '[]')) {
                            bookedRoomsCount += booking.roomCount || 1;
                        }
                    }

                    if (bookedRoomsCount >= room.maxcount) {
                        availability = false;
                        break;
                    }
                }
            }

            for (let d = moment(fromdate, 'DD-MM-YYYY'); d.isBefore(moment(todate, 'DD-MM-YYYY')); d.add(1, 'days')) {
                const dateStr = d.format('DD-MM-YYYY');

                if (room.minimumStay && room.minimumStay[dateStr]) {
                    maxMinStay = Math.max(maxMinStay, room.minimumStay[dateStr]);
                }
            }

            const selectedRangeDays = moment(todate, 'DD-MM-YYYY').diff(moment(fromdate, 'DD-MM-YYYY'), 'days');

            if (selectedRangeDays < maxMinStay) {
                availability = false;
            }

            return availability;
        });

        setRooms(filteredRooms);
    }

    const handlePickRatePlan = (ratePlanName, rate) => {
        // Automatically add the rate plan with 1 room to the booking sidebar
        setBookingSidebar(prev => [
            ...prev,
            {
                ...rate,
                selectedRooms: 1, // Default to 1 room
                selectedAdults: { 0: 1 }, // Default to 1 adult for the first room
                selectedChildren: { 0: 0 }, // Default to 0 children for the first room
                extraBed: { 0: false }, // Default no extra bed for the first room
            }
        ]);

        // Automatically show the sidebar if it is hidden
        setShowSidebar(true);
    };


    const handleRemoveRatePlan = (ratePlanId) => {
        setBookingSidebar(prev => prev.filter(plan => plan.ratePlanId !== ratePlanId));
    };

    const handleRoomChange = (ratePlanId, numberOfRooms) => {
        setBookingSidebar(prev => {
            const selectedRatePlan = prev.find(plan => plan.ratePlanId === ratePlanId);
            if (!selectedRatePlan) return prev;

            const room = rooms.find(room => room._id === selectedRatePlan.roomId);
            const maxSelectableRooms = getAvailableRooms(room, ratePlanId);

            if (numberOfRooms <= maxSelectableRooms) {
                return prev.map(plan =>
                    plan.ratePlanId === ratePlanId
                        ? { ...plan, selectedRooms: numberOfRooms }
                        : plan
                );
            } else {
                Swal.fire({
                    title: 'Error',
                    text: `Only ${maxSelectableRooms} rooms are available for the selected date range.`,
                    icon: 'error',
                });
                return prev;
            }
        });
    };

    const handleGuestInput = (ratePlanId, type, roomIndex, value) => {
        setBookingSidebar(prev => {
            return prev.map(plan =>
                plan.ratePlanId === ratePlanId
                    ? {
                        ...plan,
                        [`selected${type.charAt(0).toUpperCase() + type.slice(1)}`]: {
                            ...plan[`selected${type.charAt(0).toUpperCase() + type.slice(1)}`],
                            [roomIndex]: value
                        }
                    }
                    : plan
            );
        });
    };

    const toggleExtraBed = (ratePlanId, roomId, roomIndex) => {
        setBookingSidebar(prev => {
            return prev.map(plan =>
                plan.ratePlanId === ratePlanId && plan.roomId === roomId
                    ? {
                        ...plan,
                        extraBed: {
                            ...plan.extraBed,
                            [roomIndex]: !plan.extraBed?.[roomIndex]
                        }
                    }
                    : plan
            );
        });
    };

    const handleContinueToCheckout = () => {
        const updatedSidebar = bookingSidebar.map(item => {
            const room = rooms.find(r => r._id === item.roomId);
            const ratePlan = ratePlans[item.roomId]?.find(rp => rp.rateplanname === item.ratePlanName);

            return {
                ...item,
                roomName: room?.name || 'Unknown Room',
                imageurls: room?.imageurls || [],
                mealPlan: ratePlan?.mealPlan || item.mealPlan || 'None',
                cancellationPolicy: ratePlan?.cancellationPolicy
                    ? `${ratePlan.cancellationPolicy.chargeType} - ${ratePlan.cancellationPolicy.chargePercentage}% charge ${ratePlan.cancellationPolicy.chargeType === 'Flexible' && ratePlan.cancellationPolicy.daysBeforeCheckin
                        ? `if canceled ${ratePlan.cancellationPolicy.daysBeforeCheckin} days before`
                        : ''
                    }`
                    : item.cancellationPolicy || 'Flexible',
                fromdate,
                todate,
                ratePlanRate: ratePlan?.ratePlanRate || item.ratePlanRate || item.totalRate,
                roomId: room?._id || item.roomId,
                extraBed: item.extraBed || {},
                promoCode
            };
        });

        navigate('/booking', {
            state: {
                bookingSidebar: updatedSidebar
            }
        });
    };

    const calculateTotalDays = () => {
        if (fromdate && todate) {
            const start = moment(fromdate, 'DD-MM-YYYY');
            const end = moment(todate, 'DD-MM-YYYY');
            return end.diff(start, 'days');
        }
        return 0;
    };


    const calculateGrandTotal = () => {
        return bookingSidebar.reduce((acc, plan) => {
            // Add the total rate for rooms
            const roomTotal = plan.type !== 'addonservice' ? plan.totalRate * plan.selectedRooms : 0;

            // Add the extra bed charge if applicable
            const extraBedCharge = plan.extraBed
                ? Object.values(plan.extraBed).reduce((sum, isExtraBed) => sum + (isExtraBed ? extraBedPrice : 0), 0)
                : 0;

            // Add the service price if the plan is an add-on service
            const addOnServiceTotal = plan.type === 'addonservice' ? plan.servicePrice : 0;

            // Accumulate all charges
            return acc + roomTotal + extraBedCharge + addOnServiceTotal;
        }, 0);
    };

    // Toggle sidebar content expand/collapse
    // Toggle sidebar expand/collapse
    const toggleMinimizeSidebar = () => {
        setIsMinimized(!isMinimized);
    };


    // Toggle room expand/collapse
    const toggleRoomExpand = (roomId) => {
        setExpandedRooms(prevState => ({
            ...prevState,
            [roomId]: !prevState[roomId]
        }));
    };

    // Handle closing sidebar
    const handleCloseSidebar = () => {
        setShowSidebar(false);
    };

    const handleRemoveIndividualRoom = (ratePlanId, roomIndex) => {
        handleRoomChange(ratePlanId, roomIndex);
    };

    const handleRemoveRoomType = (roomId) => {
        setBookingSidebar(prev => prev.filter(plan => plan.roomId !== roomId));
    };

    const calculateFinalRateWithPromo = (baseRate) => {
        return promoDiscount ? baseRate * (1 - promoDiscount / 100) : baseRate;
    };

    const handleApplyPromoAndFilter = async () => {
        if (promoCode) {
            // Only apply promo code if it has a value
            try {
                const response = await axios.post('/api/promotions/validate-promo', { promoCode });
                const promotion = response.data;
                setPromoDiscount(promotion.discountPercentage);
                message.success(`Promo code applied successfully! ${promotion.discountPercentage}% discount.`);
            } catch (error) {
                setPromoDiscount(null);
                message.error('Invalid promo code.');
            }
        }

        // Filter by occupancy regardless of promo code status
        filterByOccupancy();
    };

    const calculateTotalRooms = () => {
        return bookingSidebar.reduce((total, plan) => total + (plan.selectedRooms || 0), 0);
    };


    const handleCurrencyChange = (value) => {
        setCurrency(value); // Update currency in CurrencyContext
    };

    const handleLanguageChange = (lng) => {
        i18n.changeLanguage(lng);
        setLanguage(lng); // Update state to reflect the selected language
    };


    return (
        <div
            className='background'
            style={{
                backgroundImage: `url(${branding.pageBackgroundImage})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundAttachment: 'fixed',
            }}
        >
            <div className='container'>
                <div
                    className="searchbar row mt-5 bs d-flex align-items-center"
                    style={{
                        background: branding.searchBarGradient,
                        borderRadius: '12px',
                        padding: '20px',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                        backdropFilter: 'blur(10px)',
                        border: '1px solid rgba(255, 255, 255, 0.2)',
                        transition: 'all 0.3s ease',
                    }}
                >
                    <div className="col-md-3 col-6">
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DatePicker
                                label={t("homescreen.check_in")}
                                value={startDate}
                                onChange={(date) => handleDateChange(date, 'start')}
                                format="DD-MM-YYYY"
                                slotProps={{
                                    textField: {
                                        size: 'small', // Make the DatePicker input field small
                                        className: 'form-control', // Bootstrap style, if desired
                                    },
                                }}
                            />
                        </LocalizationProvider>
                    </div>
                    <div className="col-md-3 col-6">
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DatePicker
                                label={t("homescreen.check_out")}
                                value={endDate}
                                onChange={(date) => handleDateChange(date, 'end')}
                                format="DD-MM-YYYY"
                                slotProps={{
                                    textField: {
                                        size: 'small', // Make the DatePicker input field small
                                        className: 'form-control', // Bootstrap style, if desired
                                    },
                                }}
                            />
                        </LocalizationProvider>
                    </div>
                    <div className='adult col-md-6 d-flex align-items-center mb-2'>
                        <select
                            value={adultsFilter}
                            onChange={(e) => setAdultsFilter(Number(e.target.value))}
                            className="form-control"
                            style={{ marginRight: '10px', borderColor: 'white' }}
                        >
                            <option value="" disabled>{t("homescreen.adults")}</option>
                            {[...Array(6).keys()].map(i => (
                                <option key={i} value={i}>{i}</option>
                            ))}
                        </select>
                        <select
                            value={childrenFilter}
                            onChange={(e) => setChildrenFilter(Number(e.target.value))}
                            className="form-control"
                            style={{ marginRight: '10px', borderColor: 'white' }}
                        >
                            <option value="" disabled>{t("homescreen.children")}</option>
                            {[...Array(6).keys()].map(i => (
                                <option key={i} value={i}>{i}</option>
                            ))}
                        </select>
                        <input
                            type="text"
                            className="form-control"
                            placeholder={t("homescreen.promo_code")}
                            value={promoCode}
                            onChange={(e) => setPromoCode(e.target.value)}
                            style={{ marginRight: '10px', borderColor: 'white' }}
                        />
                        <button
                            type="button"
                            className="form-control btn btn-primary mt-2 apply-button"
                            onClick={handleApplyPromoAndFilter}
                            style={{ width: 'auto' }}
                        >
                            {t("homescreen.apply")}
                        </button>

                    </div>
                </div>
                <div className='row justify-content-center mt-3 mb-5'>
                    {loading ? (
                        <h1>Loading .....</h1>
                    ) : error ? (
                        <Error />
                    ) : (
                        rooms.map((room) => (
                            <div className='col-md-9 mt-2' key={room._id}>
                                <Room
                                    room={room}
                                    fromdate={fromdate}
                                    todate={todate}
                                    ratePlans={ratePlans[room._id] || []}
                                    handlePickRatePlan={handlePickRatePlan}
                                    handleRemoveRatePlan={handleRemoveRatePlan}
                                    handleRoomChange={handleRoomChange}
                                    bookingSidebar={bookingSidebar}
                                    getAvailableRooms={getAvailableRooms}
                                    handlePickAddOnService={handlePickAddOnService}  // Pass handler to pick add-on services
                                    addonservices={addonservices}  // Pass add-on services to the Room component
                                    promoDiscount={promoDiscount}
                                />
                            </div>
                        ))
                    )}
                </div>
                <>
                    {showSidebar && bookingSidebar.length > 0 && (
                        <Draggable handle=".drag-handle" cancel=".no-drag">
                            <div className="booking-sidebar-global"
                                style={{
                                    background: branding.searchBarGradient,
                                    ...(isMobile ? {
                                        width: '100%',
                                        position: 'fixed',
                                        bottom: 0,
                                        height: isMinimized ? '50px' : '80%', // Adjust the height when minimized
                                        transition: 'height 0.3s ease-in-out' // Smooth transition
                                    } : {
                                        width: '320px'
                                    }),
                                }}  >
                                {/* Sidebar header with drag handle and close button */}
                                <div
                                    className="drag-handle"
                                    style={{
                                        ...(isMobile
                                            ? {
                                                position: 'sticky',
                                                top: '1px', // Stick below the navbar
                                                left: 0,
                                                width: '100%',
                                                zIndex: 1500,
                                                backgroundColor: branding.dragBarColor, // Apply buttonColor from branding
                                                borderColor: branding.dragBarColor,
                                                padding: '10px 20px',
                                                color: 'white',
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                borderRadius: '0', // Remove border radius on mobile
                                            }
                                            : {
                                                cursor: 'move',
                                                position: 'sticky',
                                                top: 0, // Adjust this as needed for spacing
                                                backgroundColor: branding.dragBarColor, // Apply buttonColor from branding
                                                borderColor: branding.dragBarColor,     // Match border color for consistency
                                                padding: '5px',
                                                width: '100%',
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                zIndex: 10, // Keeps it above other elements in the sidebar
                                                borderTopLeftRadius: '10px',
                                                borderTopRightRadius: '10px',
                                            }),
                                    }}
                                >
                                    {isMobile ? (
                                        <>
                                            {/* Booking details for mobile */}
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    width: '100%',
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                    }}
                                                >
                                                    <p style={{ marginTop: '2px', fontSize: '13px', marginBottom: '5px' }}>
                                                        <strong>{moment(fromdate, 'DD-MM-YYYY').format('DD MMM')} - {moment(todate, 'DD-MM-YYYY').format('DD MMM')}</strong>
                                                    </p>
                                                    <p style={{ margin: '0', fontSize: '13px' }}>
                                                        <strong>{t("homescreen.total_nights")}: {calculateTotalDays()}</strong>
                                                    </p>
                                                </div>
                                                <div
                                                    style={{
                                                        textAlign: 'right',
                                                    }}
                                                >
                                                    <p
                                                        style={{
                                                            marginRight: '30px',
                                                            fontSize: '18px',
                                                            fontWeight: 'bold',
                                                            marginTop: '7px'
                                                        }}
                                                    >
                                                        {formatCurrency(calculateGrandTotal())}
                                                    </p>
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        <span style={{ color: 'white', marginLeft: '10px' }}>
                                            <strong>{t("homescreen.your_bookings")}</strong>
                                        </span>
                                    )}
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <ShoppingCartOutlined style={{ color: 'white', marginLeft: '10px' }} />
                                        <span style={{ color: 'white', marginRight: '5px' }}>
                                            {calculateTotalRooms()}
                                        </span>
                                        <Button
                                            type="text"
                                            icon={isMinimized ? <DownOutlined /> : <UpOutlined />}
                                            onClick={toggleMinimizeSidebar}
                                            className="no-drag"
                                            style={{
                                                color: 'white',
                                                padding: '5px',
                                            }}
                                        />
                                        <CloseOutlined
                                            onClick={handleCloseSidebar}
                                            className="no-drag"
                                            style={{
                                                marginLeft: '10px',
                                                marginRight: '10px',
                                                color: 'white',
                                                cursor: 'pointer',
                                            }}
                                        />
                                    </div>
                                </div>

                                {/* Sidebar content: Initially expanded, can be minimized */}
                                {!isMinimized && (
                                    <>
                                        {!isMobile && (
                                            <div className='ms-2 mt-2 text-white'>
                                                <p><strong>{t("homescreen.check_in")}:</strong> {fromdate}</p>
                                                <p><strong>{t("homescreen.check_out")}:</strong> {todate}</p>
                                                <p><strong>{t("homescreen.total_nights")}:</strong> {calculateTotalDays()}</p>
                                            </div>
                                        )}
                                        <Divider />

                                        <div className="booking-sidebar-content">
                                            {/* Group add-on services by their service ID and count their occurrences */}
                                            {bookingSidebar.reduce((acc, plan) => {
                                                if (plan.type === 'addonservice') {
                                                    const existing = acc.find(item => item.serviceId === plan.serviceId);
                                                    if (existing) {
                                                        existing.quantity += 1;
                                                    } else {
                                                        acc.push({ ...plan, quantity: 1 });
                                                    }
                                                } else {
                                                    acc.push(plan);
                                                }
                                                return acc;
                                            }, []).map((plan, index) => (
                                                <div key={index}>
                                                    {/* Check if it's an add-on service */}
                                                    {plan.type === 'addonservice' ? (
                                                        <div className="addonservice-summary text-white">
                                                            <p><strong>{t("homescreen.add_on_service")}:</strong></p>
                                                            <p><strong>{plan.serviceName}:</strong> <Tag color="green"> {formatCurrency(plan.servicePrice)}{currency}</Tag> {plan.chargeType}</p>
                                                            <p><strong>{t("homescreen.quantity")}:</strong> {plan.quantity}</p>
                                                            <DeleteOutlined onClick={() => handleRemoveAddOnService(plan.serviceId)} style={{ cursor: 'pointer', color: 'red' }} />
                                                        </div>
                                                    ) : (
                                                        <div className="rate-plan-summary text-white">
                                                            {/* Toggle for room details (visible by default, can be minimized) */}
                                                            <div
                                                                onClick={() => toggleRoomExpand(plan.roomId)}
                                                                style={{ cursor: 'pointer', display: 'flex', justifyContent: 'space-between' }}
                                                            >
                                                                <h5 style={{ color: '#FFF', fontFamily: 'Bebas Neue', fontSize: '28px' }}>
                                                                    <strong>{rooms.find(r => r._id === plan.roomId)?.name}</strong>
                                                                </h5>
                                                                <div>
                                                                    {expandedRooms[plan.roomId] ? <DownOutlined /> : <UpOutlined />}
                                                                    <DeleteOutlined
                                                                        onClick={() => handleRemoveRoomType(plan.roomId)}
                                                                        style={{ cursor: 'pointer', color: 'red', marginLeft: '10px' }}
                                                                    />
                                                                </div>
                                                            </div>

                                                            {/* Room rate always shown */}
                                                            <p><strong>{t("homescreen.room_rate")}:</strong> <Tag color="green">{formatCurrency(plan.totalRate * plan.selectedRooms)} {currency}</Tag></p>


                                                            {/* Room details are always shown initially */}
                                                            {!expandedRooms[plan.roomId] && (
                                                                <div>
                                                                    <p><strong>{t("homescreen.rate_plan")}:</strong> {plan.ratePlanName}</p>
                                                                    {[...Array(plan.selectedRooms).keys()].map((roomIndex) => (
                                                                        <div key={roomIndex} className="guest-inputs mt-4">
                                                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                                                <p><strong>{t("homescreen.room")} {roomIndex + 1}</strong></p>
                                                                                <DeleteOutlined
                                                                                    onClick={() => handleRemoveIndividualRoom(plan.ratePlanId, roomIndex)}
                                                                                    style={{ cursor: 'pointer', color: 'red' }}
                                                                                />
                                                                            </div>

                                                                            <div className="d-flex align-items-center" style={{ gap: '15px' }}>
                                                                                <label><strong>{t("homescreen.adults")}:</strong></label>
                                                                                <select
                                                                                    value={plan.selectedAdults?.[roomIndex] || 0}
                                                                                    onChange={(e) => handleGuestInput(plan.ratePlanId, 'adults', roomIndex, Number(e.target.value))}
                                                                                    className="form-select"
                                                                                    style={{ width: '60px', fontSize: '10px', padding: '2px' }}
                                                                                >
                                                                                    {(() => {
                                                                                        const room = rooms.find(r => r._id === plan.roomId);
                                                                                        return [...Array((room?.maxadult || 0) + 1).keys()].map(i => (
                                                                                            <option key={i} value={i}>{i}</option>
                                                                                        ));
                                                                                    })()}
                                                                                </select>

                                                                                <label><strong>{t("homescreen.children")}:</strong></label>
                                                                                <select
                                                                                    value={plan.selectedChildren?.[roomIndex] || 0}
                                                                                    onChange={(e) => handleGuestInput(plan.ratePlanId, 'children', roomIndex, Number(e.target.value))}
                                                                                    className="form-select"
                                                                                    style={{ width: '60px', fontSize: '10px', padding: '2px' }}
                                                                                >
                                                                                    {(() => {
                                                                                        const room = rooms.find(r => r._id === plan.roomId);
                                                                                        return [...Array((room?.maxchild || 0) + 1).keys()].map(i => (
                                                                                            <option key={i} value={i}>{i}</option>
                                                                                        ));
                                                                                    })()}
                                                                                </select>
                                                                            </div>

                                                                            <div className="d-flex align-items-center mt-2">
                                                                                <label><strong>{t("homescreen.extra_bed")}:</strong></label>
                                                                                <Button
                                                                                    type="primary"
                                                                                    onClick={() => toggleExtraBed(plan.ratePlanId, plan.roomId, roomIndex)}
                                                                                    style={{ marginLeft: '1rem', fontSize: '10px', padding: '2px 4px', height: '24px' }}
                                                                                >
                                                                                    {plan.extraBed?.[roomIndex] ? 'Remove' : 'Add'}
                                                                                </Button>
                                                                            </div>
                                                                            {plan.extraBed?.[roomIndex] && (
                                                                                <p>Extra Bed Fee: <Tag color="red">{formatCurrency(extraBedPrice)} {currency}</Tag></p>
                                                                            )}
                                                                        </div>
                                                                    ))}
                                                                    <div className="d-flex align-items-center mt-3">
                                                                        <label><strong>{t("homescreen.no_of_room")}::</strong></label>
                                                                        <select
                                                                            value={plan.selectedRooms}
                                                                            onChange={(e) => handleRoomChange(plan.ratePlanId, Number(e.target.value))}
                                                                            className="form-select"
                                                                            style={{ marginLeft: '1rem', width: '70px', fontSize: '10px', padding: '1px 2px' }}
                                                                        >
                                                                            {(() => {
                                                                                const room = rooms.find(r => r._id === plan.roomId);
                                                                                const maxSelectableRooms = getAvailableRooms(room, plan.ratePlanId);
                                                                                return [...Array(maxSelectableRooms + 1).keys()].map(i => (
                                                                                    <option key={i} value={i}>{i}</option>
                                                                                ));
                                                                            })()}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    )}
                                                    {index < bookingSidebar.length - 0 && <Divider />}
                                                </div>
                                            ))}
                                            <p style={{ fontSize: '20px', fontStyle: 'bold', color: '#FFF', fontFamily: 'Bebas Neue', }}>
                                                <strong>{t("homescreen.grand_total")}:  </strong>
                                                <Tag color="blue" style={{ fontSize: '20px', padding: '5px', fontFamily: 'Bebas Neue' }}>
                                                    <strong>{formatCurrency(calculateGrandTotal())}</strong>
                                                </Tag>
                                            </p>
                                            <div className="sticky-footer">
                                                <Button
                                                    variant="primary"
                                                    onClick={handleContinueToCheckout}
                                                    style={{
                                                        width: '100%',
                                                        color: branding.roomNameColor,
                                                        fontSize: '16px',
                                                        fontWeight: 'bold',
                                                    }}
                                                >
                                                    <strong>{t("homescreen.continue_checkout")}</strong>
                                                </Button>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        </Draggable>
                    )}
                </>

            </div>
            {/* Sticky Dropdowns for Language and Currency */}
            <div
                className="sticky-dropdowns"
                style={{
                    position: 'fixed',
                    bottom: '20px',
                    right: '20px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '10px',
                    zIndex: 1000,
                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                    padding: '10px',
                    borderRadius: '8px',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                }}
            >
                <Select
                    value={language}
                    onChange={handleLanguageChange}
                    bordered={false}
                    style={{ width: 150 }}
                >
                    <Option value="en">🇺🇸 {t('English')}</Option>
                    <Option value="vi">🇻🇳 {t('Vietnamese')}</Option>
                    <Option value="ko">🇰🇷 {t('Korean')}</Option>
                    <Option value="zh">🇨🇳 {t('Chinese')}</Option>
                    <Option value="ja">🇯🇵 {t('Japanese')}</Option>
                </Select>

                <Select
                    defaultValue={currency}
                    onChange={handleCurrencyChange}
                    bordered={false}
                    style={{ width: 150 }}
                >
                    <Option value="USD">$ USD</Option>
                    <Option value="VND">₫ VND</Option>
                    <Option value="EUR">€ EUR</Option>
                    <Option value="JPY">¥ JPY</Option>
                    <Option value="CNY">¥ CNY</Option>
                    <Option value="KRW">₩ KRW</Option>
                    <Option value="THB">฿ THB</Option>
                </Select>
            </div>
        </div>
    );
}

export default Homescreen;
